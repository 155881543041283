import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
} from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import CustomerTable from "./CustomerTable"
import { exportDataExcelCustomers } from "pages/ExportData"

function Customers() {
  document.title = `Customers | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { deleteApi, getApi, postApi, putApi } = useApi()

  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [screenLoad, setScreenLoad] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [totalRecords, setTotalRecords] = useState(0)
  const [displayStart, setDisplayStart] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("authUser")).RoleName == "Admin" ? 0 : JSON.parse(localStorage.getItem("authUser")).Id)
  const [userControls, setUserControls] = useState([])
  const [selectedUser, setSelectedUser] = useState()

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }

  // filter data for color
  const filterData = textFilter => {
    setSearchText(textFilter == undefined ? '' : textFilter)
  }
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("authUser")).RoleId == 1) {
      getApi("User/vendor-lookup").then(({ data, status }) => {
        if (status === 200) {
          const itemData = data.map(item => ({
            label: item.Value,
            value: item.Key,
          }));
          itemData.unshift({
            label: "Users",
            value: 0,
          });
          setSelectedUser(itemData[0])
          setUserControls(itemData)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }, [])

  // color list api
  const customerListApi = () => {
    setIsLoading(true)
    postApi("Customer/list", {
      userId: parseInt(userId),
      fromAdmin: JSON.parse(localStorage.getItem("authUser")).RoleName == "Admin",
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.CustomerList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // color list api useEffect
  useEffect(() => {
    customerListApi()
  }, [rowsPerPage, searchText, displayStart, userId])

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        width: "20%",
      },
      {
        Header: "Contact No.1",
        accessor: "ContactNo1",
        width: "10%",
      },
      {
        Header: "Contact No.2",
        accessor: "ContactNo2",
        width: "10%",
      },
      {
        Header: "No. of Booking",
        accessor: "NumerOfBooking",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center"
            }}
            >
              {row?.original?.NumerOfBooking}
            </div>
          )
        },
      },
      {
        Header: "Address",
        accessor: "Address",
      },
    ],
    [tableData]
  )

  function writeDataToExcel() {
    setIsLoading(true)
    let header = ['Name', "Customer No1.", "Customer No2.", "No. of Booking", "Address"];
    const mainTitle = "Customers";
    const widthObj = [{
      Key: "A",
      Value: 30,
    },
    {
      Key: "B",
      Value: 15,
    },
    {
      Key: "C",
      Value: 15,
    },
    {
      Key: "D",
      Value: 15,
    },
    {
      Key: "E",
      Value: 50,
    },
    ]
    const extention = "Customers.xlsx"
    let excelSheetData = []

    postApi("Customer/list", {
      userId: parseInt(userId),
      fromAdmin: JSON.parse(localStorage.getItem("authUser")).RoleName == "Admin",
      displayStart: 1,
      pageSize: 10000000,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        for (let index = 0; index < data.CustomerList?.length; index++) {
          let dataObj = {
            Name: data.CustomerList[index]?.Name,
            ContactNo1: data.CustomerList[index]?.ContactNo1,
            ContactNo2: data.CustomerList[index]?.ContactNo2,
            NumerOfBooking: data.CustomerList[index]?.NumerOfBooking,
            Address: data.CustomerList[index]?.Address,
          }
          excelSheetData.push(dataObj)
        }
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      var fields = Object.keys(excelSheetData[0]);
      var sheetData = excelSheetData.map(function (row) {
        return fields.map(function (fieldName) {
          return row[fieldName];
        });
      });
      setIsLoading(false)
      sheetData.unshift(header);
      exportDataExcelCustomers(sheetData, mainTitle, widthObj, extention)
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        {screenLoad && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <CustomerTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isColorList={true}
              customPageSize={10}
              className="custom-header-css"
              filterData={filterData}
              isLoading={isLoading}
              pagePerSize={pagePerSize}
              TotalRecords={totalRecords}
              setUserId={setUserId}
              userId={userId}
              userControls={userControls}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              writeDataToExcel={writeDataToExcel}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Customers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  onFilterChange: PropTypes.func,
}

export default Customers
