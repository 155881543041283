import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
} from "reactstrap"
import { dateFormat, getCurrentFinancialYear } from "pages/Common"
import toast from "react-hot-toast"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import useApi from "common/ApiMiddlewere"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import DepositTable from "./DepositTable"
import { exportDataExcelDepoosit } from "pages/ExportData"

function DepositReport() {
    document.title = `Security | ${localStorage.getItem("ShopName")}`
    const { postApi } = useApi()
    let { pathname } = useLocation()

    // useStates
    const [tableData, setTableData] = useState([])
    const [modal1, setModal1] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [TotalRecords, setTotalRecords] = useState(0)
    const [TotalReceive, setTotalReceive] = useState(0)
    const [TotalReturn, setTotalReturn] = useState(0)
    const [date, setdate] = useState([new Date(), new Date()])
    const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", bookingItemId: "", returned: false })
    const [displayStart, setDisplayStart] = useState(1)
    const [searchText, setSearchText] = useState("")
    const [receiveReturnDeposit, setReceiveReturnDeposit] = useState(1)
    const [dateDisble, setdateDisble] = useState(true)
    const [dueDeposit, setDueDeposit] = useState(false)
    const startYear = localStorage.getItem("FinancialYearFrom")
    const endYear = localStorage.getItem("FinancialYearTo")
    const SelectedFinancialYear = startYear?.split("-")[0] + "-" + (endYear?.split("-")[0])

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    useEffect(() => {
        if (!dateDisble) {
            let Date1 = [`01-04-${SelectedFinancialYear?.split('-')[0]}`, `31-03-${SelectedFinancialYear?.split('-')[1]}`]
            const convertedDates = Date1.map(dateStr => {
                const [day, month, year] = dateStr.split('-');
                const dateObj = new Date(year, month - 1, day);
                return dateObj
            });
            setdate(convertedDates)
        }
    }, [dateDisble])

    // handlers
    const toggle1 = () => {
        setModal1(!modal1)
    }

    const depositReportApi = () => {
        setIsLoading(true)
        postApi("Report/deposit-report", {
            depositType: parseInt(receiveReturnDeposit),
            fromDate: !dateDisble ? date[0] : dateFormat(date[0], "yyyy-MM-dd"),
            toDate: !dateDisble ? date[1] : dateFormat(date[1], "yyyy-MM-dd"),
            DueDeposit: dueDeposit,
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.DepositList)
                setTotalRecords(data.TotalRecords)
                setTotalReceive(data.TotalReceive)
                setTotalReturn(data.TotalReturn)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // booking list API useEffect
    useEffect(() => {
        if (dueDeposit) {
            setdateDisble(false)
        }
        if (date[1] != undefined) {
            depositReportApi()
        }
    }, [
        date,
        rowsPerPage,
        searchText,
        displayStart,
        receiveReturnDeposit,
        dueDeposit
    ])
    // view booking
    const handleViewBooking = bookingId => {
        setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
        toggle1()
    }

    // Booking table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Bill No.",
                accessor: "BillNo",
                width: "4%",
                Cell: ({ row }) => {

                    return <>
                        {row.original.TotalProduct == 0 ? (
                            <div style={{ color: "#E94560" }} >{row.original.BillNo}</div>
                        ) : (
                            <div
                                style={{ cursor: "pointer", color: "#556ee6" }}
                                onClick={() => {
                                    handleViewBooking(row.original.Id)
                                }}
                            >
                                {row.original.BillNo}
                            </div>
                        )}
                    </>
                },
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Customer No.",
                accessor: "CustomerNo",
                width: "6%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Rent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Advance
                    </div>
                ),
                accessor: "Advance",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Advance || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Receive Security Amt.
                    </div>
                ),
                accessor: "ReceiveDeposit",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.ReceiveDeposit || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Return Security Amt.
                    </div>
                ),
                accessor: "ReturnDeposit",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.ReturnDeposit || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Receive Date",
                accessor: "ReceiveDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.ReceiveDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original?.ReceiveDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Return Date",
                accessor: "ReturnDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.ReturnDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original?.ReturnDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Status",
                width: "8%",
                // width: 100,
                accessor: "Status",
                Cell: ({ row }) => {
                    return (
                        <span>
                            {row.original.Status == 1 ? "Booked" : row.original.Status == 2 ? "Delivered" : row.original.Status == 3 ? "Return" : "Cancel"}
                        </span>
                    )
                },
            },
        ],
        [tableData]
    )

    // Excel export data
    function writeDataToExcel() {
        setIsLoading(true)
        let header = ["Bill No.", 'Name', "Customer No.", "Rent", "Advance", "Receive Security Amt.", "Return Security Amt.", "Receive Date", "Return Date", "Status"];
        const mainTitle = "Products History";
        const widthObj = [{
            Key: "A",
            Value: 12,
        },
        {
            Key: "B",
            Value: 12,
        },
        {
            Key: "C",
            Value: 20,
        },
        {
            Key: "D",
            Value: 16,
        },
        {
            Key: "E",
            Value: 11,
        },
        {
            Key: "F",
            Value: 20,
        },
        {
            Key: "F",
            Value: 20,
        },
        {
            Key: "G",
            Value: 20,
        },
        {
            Key: "H",
            Value: 14,
        },
        {
            Key: "I",
            Value: 14,
        },
        {
            Key: "J",
            Value: 20,
        },
        {
            Key: "K",
            Value: 14,
        },
        ]
        const extention = "Security.xlsx"
        let excelSheetData = []

        postApi("Report/deposit-report", {
            depositType: parseInt(receiveReturnDeposit),
            fromDate: !dateDisble ? date[0] : dateFormat(date[0], "yyyy-MM-dd"),
            toDate: !dateDisble ? date[1] : dateFormat(date[1], "yyyy-MM-dd"),
            DueDeposit: dueDeposit,
            displayStart: displayStart,
            pageSize: 100000,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                for (let index = 0; index < data.DepositList?.length; index++) {
                    let dataObj = {
                        BillNo: data.DepositList[index].BillNo,
                        Name: data.DepositList[index]?.Name,
                        CustomerNo: data.DepositList[index]?.CustomerNo,
                        Rent: Math.round(data.DepositList[index].Rent)?.toFixed(2),
                        Advance: Math.round(data.DepositList[index].Advance)?.toFixed(2),
                        ReceiveDeposit: Math.round(data.DepositList[index].ReceiveDeposit)?.toFixed(2),
                        ReturnDeposit: Math.round(data.DepositList[index].ReturnDeposit)?.toFixed(2),
                        ReceiveDate: dateFormat(data.DepositList[index].ReceiveDate, "dd/MM/yyyy"),
                        ReturnDate: data.DepositList[index].ReturnDate == "0001-01-01T00:00:00"
                            ? "" : dateFormat(data.DepositList[index].ReturnDate, "dd/MM/yyyy"),
                        Status: data.DepositList[index].Status == 1 ? "Booked" : data.DepositList[index].Status == 2 ? "Delivered" : data.DepositList[index].Status == 3 ? "Return" : "Cancel",
                    }
                    excelSheetData.push(dataObj)
                }
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            var fields = Object.keys(excelSheetData[0]);
            var sheetData = excelSheetData.map(function (row) {
                return fields.map(function (fieldName) {
                    return row[fieldName];
                });
            });
            setIsLoading(false)
            sheetData.unshift(header);
            exportDataExcelDepoosit(sheetData, mainTitle, widthObj, extention, data?.TotalReceive, data?.TotalReturn)
        })
    }

    return (
        <div className="page-content background">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <DepositTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={20}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                            className="custom-header-css"
                            date={date}
                            setdate={setdate}
                            TotalReceive={TotalReceive}
                            TotalReturn={TotalReturn}
                            receiveReturnDeposit={receiveReturnDeposit}
                            setReceiveReturnDeposit={setReceiveReturnDeposit}
                            dateDisble={dateDisble}
                            writeDataToExcel={writeDataToExcel}
                            setdateDisble={setdateDisble}
                            dueDeposit={dueDeposit}
                            setDueDeposit={setDueDeposit}
                        />

                        <InvoiceDetalis
                            toggle={toggle1}
                            modal={modal1}
                            bookingId={BookingIdandStatusId.bookingId}
                        />

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
DepositReport.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default DepositReport