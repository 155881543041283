import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { routes } from "routes/path"
import useApi from "common/ApiMiddlewere"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import GrromWellIcon from "../../assets/images/GroomWellIcon.png"

// const { RoleName } = JSON.parse(localStorage.getItem("authUser"))

const SidebarContent = props => {
  const { deleteApi, getApi, postApi, putApi } = useApi()
  const [RoleName, setRoleName] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  const [enableGroomWellMenu, setEnableGroomWellMenu] = useState(false)


  useEffect(() => { }, [props.success])

  useEffect(() => {
    postApi("Setting/setting-by-keys", {
      Key: ["ENABLE_GROOMWELL"],
      userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
    })
      .then(async ({ data, status }) => {
        if (status === 200) {
          setEnableGroomWellMenu(data[0]?.Value == "1")
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })

  }, [])
  const financeAmountShowHide = useSelector(state => state.ReduxData.finaceAmountShow);

  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()

  const activeMenu = useCallback(() => {
    let pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName == routes.userCreate || pathName == routes.userUpdate) {
        pathName = routes.userList
      }
      if (
        pathName == routes.createProduct ||
        pathName == routes.updateproduct
      ) {
        pathName = routes.productList
      }
      if (
        pathName == routes.createbooking ||
        pathName == routes.updatebooking ||
        pathName == routes.viewBooking
      ) {
        pathName = routes.bookingList
      }
      if (
        pathName == routes.createSale ||
        pathName == routes.updateSale
      ) {
        pathName = routes.salesList
      }
      if (
        pathName == routes.createPurchase ||
        pathName == routes.UpdatePurchase
      ) {
        pathName = routes.purchaseList
      }
      if (
        pathName == routes.CreateCreditNote
      ) {
        pathName = routes.CreditNote
      }
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }


    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])


  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <>
      {RoleName.RoleName == "Admin" ? (
        <React.Fragment>
          <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{props.t("Dashboard")} </li>
                <li>
                  <Link to={routes.dashboard}>
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li className="menu-title">{props.t("Menu")}</li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Master")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.userList}>{props.t("Users")}</Link>
                    </li>
                    <li>
                      <Link to={routes.inquiries}>{props.t("Inquiries")}</Link>
                    </li>
                    <li>
                      <Link to={routes.plan}>{props.t("Plan")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="  bx bxs-detail"></i>
                    <span>{props.t("Report")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.finance}>{props.t("Finance")}</Link>
                    </li>
                    <li>
                      <Link to={routes.feedback}>{props.t("Feedback")}</Link>
                    </li>
                    <li>
                      <Link to={routes.customers}>{props.t("Customers")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={routes.subscription}>
                    <i className="bx bxs-calendar"></i>
                    <span>{props.t("Subscriptions")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.setting}>
                    <i className="bx bxs-cog"></i>
                    <span>{props.t("Setting")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.dbBackup}>
                    <i className="bx bxs-data"></i>
                    <span>{props.t("Db-Backup")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.tutorial}>
                    <i className="bx bx-video"></i>
                    <span>{props.t("Tutorial")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      ) : RoleName.RoleName == "Vendor" ? (
        <React.Fragment>
          <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{props.t("Dashboard")} </li>
                <li>
                  <Link to={routes.dashboard}>
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.available}>
                    <i className="bx bx-calendar-check"></i>
                    <span>{props.t("Available")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.productAvailable}>
                    <i className="bx bx-list-check fs-2"></i>
                    <span>{props.t("Product Available")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.catalogue}>
                    <i className="bx bx-images"></i>
                    <span>{props.t("Catalogue")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={routes.exportProduct}>
                    <i className="bx bx-list-check" style={{ fontSize: "27px" }}></i>
                    <span>{props.t("GroomWell Catalogue")}</span>
                  </Link>
                </li> */}

                <li className="menu-title">{props.t("Menu")}</li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Master")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.userList}>{props.t("Users")}</Link>
                    </li>
                    <li>
                      <Link to={routes.categories}>
                        {props.t("Categories")}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.accessory}>{props.t("Accessory")}</Link>
                    </li>
                    <li>
                      <Link to={routes.size}>{props.t("Size")}</Link>
                    </li>
                    <li>
                      <Link to={routes.color}>{props.t("Colors")}</Link>
                    </li>
                    <li>
                      <Link to={routes.timeslot}>{props.t("Time Slots")}</Link>
                    </li>
                    <li>
                      <Link to={routes.reminder}>{props.t("Reminder")}</Link>
                    </li>
                    <li>
                      <Link to={routes.accounts}>{props.t("Accounts")}</Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-store"></i>
                    <span>{props.t("Inventory")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.productList}>{props.t("Products")}</Link>
                    </li>
                    <li>
                      <Link to={routes.importProduct}>
                        {props.t("Import Product")}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.items}>{props.t("Items")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-money"></i>
                    <span>{props.t("Transaction")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.bookingList}>{props.t("Bookings")}</Link>
                    </li>
                    {/* <li>
                      <Link to={routes.HoldBillList}>{props.t("Hold Bills")}</Link>
                    </li> */}
                    <li>
                      <Link to={routes.income}>{props.t("Income")}</Link>
                    </li>
                    <li>
                      <Link to={routes.expenses}>{props.t("Expense")}</Link>
                    </li>
                    <li>
                      <Link to={routes.salesList}>{props.t("Sales")}</Link>
                    </li>
                    <li>
                      <Link to={routes.purchaseList}>{props.t("Purchases")}</Link>
                    </li>
                    <li>
                      <Link to={routes.washing}>{props.t("Washing")}</Link>
                    </li>
                    <li>
                      <Link to={routes.CreditNote}>{props.t("Credit Notes")}</Link>
                    </li>
                    <li>
                      <Link to={routes.journalVouchers}>{props.t("Journal Vouchers")}</Link>
                    </li>
                    {/* <li>
                      <Link to={routes.HoldBillList}>{props.t("Hold Bills")}</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="  bx bxs-detail"></i>
                    <span>{props.t("General Report")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.delivery}>{props.t("Delivery")}</Link>
                    </li>
                    <li>
                      <Link to={routes.return}>{props.t("Return")}</Link>
                    </li>
                    <li>
                      <Link to={routes.security}>{props.t("Security")}</Link>
                    </li>
                    <li>
                      <Link to={routes.accessoryReport}>{props.t("Accessory")}</Link>
                    </li>
                    <li>
                      <Link to={routes.productHistory}>
                        {props.t("Product History")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={routes.BookedOrderReport}>
                        {props.t("Booked Orders")}
                      </Link>
                    </li> */}
                    <li>
                      <Link to={routes.stockRegister}>
                        {props.t("Stock Register")}
                      </Link>
                    </li>
                    <li style={{ display: financeAmountShowHide ? "block" : "none" }}>
                      <Link to={routes.salesman}>{props.t("Salesman")}</Link>
                    </li>
                    <li>
                      <Link to={routes.customers}>{props.t("Customers")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow" style={{ display: financeAmountShowHide ? "block" : "none" }}>
                    <i className="bx bx-bar-chart-square"></i>
                    <span>{props.t("Finance Report")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.pendingBills}>{props.t("Pending Bills")}</Link>
                    </li>
                    <li>
                      <Link to={routes.incomeExpense}>{props.t("Income/Expense")}</Link>
                    </li>
                    <li>
                      <Link to={routes.accountLedger}>{props.t("Account Ledger")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow" style={{ display: financeAmountShowHide ? "block" : "none" }}>
                    {/* <i className="bx bx bx-calculator"></i> */}
                    <i className="mdi mdi-file-percent-outline"></i>
                    {/* bx bx-poll
                    bx bx-receipt */}
                    <span>{props.t("GST Report")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.BookedOrderReport}>{props.t("Booked Orders")}</Link>
                    </li>
                    <li>
                      <Link to={routes.salesGSTReport}>{props.t("Sales")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow" style={{ display: enableGroomWellMenu ? "block" : "none" }}>
                    <img
                      // alt="sidebar bg image"
                      width="21"
                      className="groomwellImg"
                      src={GrromWellIcon}
                    />
                    <span style={{ marginLeft: "12px" }}>{props.t("GroomWell")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.groomWellProfile}>{props.t("Profile")}</Link>
                    </li>
                    <li>
                      <Link to={routes.mapCategory}>{props.t("Map Category")}</Link>
                    </li>
                    <li>
                      <Link to={routes.exportProduct}>{props.t("Export Product")}</Link>
                    </li>
                    <li>
                      <Link to={routes.groomWellProduct}>{props.t("Product")}</Link>
                    </li>
                    {/* <li>
                      <Link to={routes.accountLedger}>{props.t("Analytics")}</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to={routes.setting}>
                    <i className="bx bxs-cog"></i>
                    <span>{props.t("Setting")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.systemLog}>
                    <i className="mdi mdi-file-search-outline"></i>
                    <span>{props.t("System Logs")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.releseNote}>
                    <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span>
                    <i className="bx bx-notepad"></i>
                    <span>{props.t("What's New")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.tutorialForVendor}>
                    <i className="bx bx-video"></i>
                    <span>{props.t("Tutorial")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.rentopus.in/contact-us.html" target="_blank">
                    <i className="bx bx-info-circle"></i>
                    {/* <i className="bx bxs-info-circle"></i> */}
                    <span>{props.t("About us")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      ) : (
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to={routes.available}>
                  <i className="fas fa-calendar-check"></i>
                  <span>{props.t("Available")}</span>
                </Link>
              </li>
              <li>
                <Link to={routes.productAvailable}>
                  <i className="bx bx-list-check"></i>
                  <span>{props.t("Product Available")}</span>
                </Link>
              </li>
              <li>
                <Link to={routes.catalogue}>
                  <i className="bx bx-images"></i>
                  <span> {props.t("Catalogue")}</span>
                </Link>
              </li>
              <li>
                <Link to={routes.tutorialForVendor}>
                  <i className="bx bx-video"></i>
                  <span>{props.t("Tutorial")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      )}
    </>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
