import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import * as Yup from "yup"

import { useFormik } from "formik"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"

import SubscriptionViewTable from "./SubscriptionViewTable"

import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SubscriptionModel from "components/Common/SubscriptionModel"
import { UserInvoicePdf } from "pages/Master/User/UserInvoicePDF"
import jsPDF from "jspdf"
import { UserFont } from "pages/Master/User/UserFont"
import SubscriptionTable from "./SubscriptionTable"

function Subscription() {
  document.title = `Subscriptions | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  let { state } = useLocation()
  const { getApi, postApi } = useApi()

  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [tableData, setTableData] = useState([])
  const [userId, setUserId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [SubscriptionData, setSubscriptionData] = useState({
    userId: "",
    planId: "",
    amount: "",
    discount: "",
    totalAmount: "",
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    remarks: "",
  })
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [displayStart, setDisplayStart] = useState(1)
  const [dateDisble, setdateDisble] = useState(false)
  const [date, setdate] = useState([new Date(), state ? new Date().getTime() + 10 * 24 * 60 * 60 * 1000 : new Date()])
  const [isDemoUser, setIsDemoUser] = useState(false)

  // filterData for subscription
  const filterData = textFilter => {
    setSearchText(textFilter)
  }

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }
  // subscription list api
  const subscriptionApi = () => {
    setIsLoading(true)
    postApi("SubscriptionLogs/subscription-list", {
      fromDate: !dateDisble ? null : date[0] ? dateFormat(
        date[0],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      toDate: !dateDisble ? null : date[1] ? dateFormat(
        date[1],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      demoUser: isDemoUser,
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.SubscriptionList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // subscription list api useEffect
  useEffect(() => {
    if (date[1] != undefined) {
      subscriptionApi()
    }
  }, [rowsPerPage, searchText, displayStart, date, dateDisble,isDemoUser])

  // view button : Subscription View handler
  const handleProductView = userId => {
    if (userId) {
      setIsEdit(true)
      setUserId(userId)
      toggle()
    }
  }

  // Create subscription handler
  const handleSubscriptionClick = () => {
    setIsEdit(false)
    toggle()
  }

  // subscription Validations
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: SubscriptionData,

    validationSchema: Yup.object({
      userId: Yup.string().required("The User is required"),
      planId: Yup.string().required("The Plan is required"),
      amount: Yup.string().required("The Amount is required"),
      startDate: Yup.string().required("The StartDate is required"),
      endDate: Yup.string().required("The endDate is required"),
    }),
    onSubmit: values => {
      if (values) {
        toggle()
        postApi("SubscriptionLogs/renew-subscription", {
          userId: values.userId,
          planId: values.planId,
          amount: values.amount,
          discount: values.discount,
          startDate: values.startDate,
          endDate: values.endDate,
          remarks: values.remarks,
        }).then(({ data, status }) => {
          if (status == 400) {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })

            validation.handleReset()
          } else {
            toast.success("Sucessfully created", {
              style: {
                fontSize: "15px",
              },
            })
            subscriptionApi()
            validation.handleReset()
            setSubscriptionData({
              userId: "",
              planId: "",
              amount: "",
              discount: "",
              totalAmount: "",
              startDate: new Date().toISOString().slice(0, 10),
              endDate: new Date().toISOString().slice(0, 10),
              remarks: "",
            })
          }
        })
      }
    },
  })
  // popup box handler
  const toggle = () => {
    setModal(!modal)
  }

  const handleDownloadPdf = async id => {
    setIsLoading(true)

    const html = await UserInvoicePdf(id, getApi)
    let { data: { BillNo }, status } = await getApi(`User/customer-invoice?id=${id}`)
    const doc = new jsPDF({
      format: "a5",
      unit: "px",
      filters: ["ASCIIHexEncode"],
      compress: true,
    })
    // var imgData = "data:image/jpeg;base64," + imagebase64
    doc.addFileToVFS("NotoSansGujarati-Regular.ttf", UserFont)
    doc.addFont(
      "NotoSansGujarati-Regular.ttf",
      "NotoSansGujarati-Regular",
      "normal"
    )
    doc.setFont("NotoSansGujarati-Regular") // set font
    doc.html(html, {
      async callback(doc) {
        setIsLoading(false)
        await doc.save(`invoice_${BillNo}.pdf`)
      },
      margin: [10, 10, 10, 10],
    })
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "Username",
      },
      {
        Header: "Full Name",
        accessor: "FullName",
      },
      {
        Header: "Shop Name",
        accessor: "ShopName",
      },
      {
        Header: "Contact#",
        accessor: "ContactNo",
      },
      {
        Header: "Current Plan",
        accessor: "PlanName",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Plan Amt.
          </div>
        ),
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Amount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Discount
          </div>
        ),
        accessor: "Discount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Discount == null || row.original.Discount == ""
                ? "0.00"
                : row.original.Discount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Activation Date",
        accessor: "ActivationDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ActivationDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.ActivationDate.split("T")[0],
                "dd/MM/yyyy"
              )}
          </p>
        ),
      },
      {
        Header: "Start Date",
        accessor: "StartDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.StartDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.StartDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "End Date",
        accessor: "EndDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.EndDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.EndDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "7%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 far fa-file-pdf "
                  style={{ color: "#f39900", cursor: "pointer" }}
                  id={`Invoice${row.original.Id}`}
                  onClick={() => handleDownloadPdf(row.original.Id)}
                ></i>
                <span className="mytext">Invoice</span>
              </li>
              <li className="mytooltip">
                <i
                  className=" fs-6 far fa-eye"
                  style={{ color: "#556ee6", cursor: "pointer" }}
                  id={`viewtooltip${row.original.Id}`}
                  onClick={() => handleProductView(row.original.Id)}
                ></i>
                <span className="mytext">View</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SubscriptionTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isSubscriptionList={true}
              handleSubscriptionClick={handleSubscriptionClick}
              customPageSize={10}
              isLoading={isLoading}
              filterData={filterData}
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              dateDisble={dateDisble}
              setdateDisble={setdateDisble}
              date={date}
              setdate={setdate}
              className="custom-header-css"
              setIsDemoUser={setIsDemoUser}
              isDemoUSer={isDemoUser}
            />

            {isEdit ? (
              <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="small"
              >
                <div className="modal-content">
                  <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                    subscriptions
                  </ModalHeader>
                  <ModalBody>
                    <SubscriptionViewTable userId={userId} />
                  </ModalBody>
                </div>
              </Modal>
            ) : (
              <>
                <SubscriptionModel
                  modal={modal}
                  toggle={toggle}
                  subscriptionApi={subscriptionApi}
                />
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

Subscription.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  onFilterChange: PropTypes.func,
}

export default Subscription
