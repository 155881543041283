import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "../../../components/Common/TableContainer"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import jsPDF from "jspdf"
import { UserInvoicePdf } from "./UserInvoicePDF"
import { UserFont } from "./UserFont"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import { dateFormat, dateTimeFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import { routes } from "routes/path"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useFormik } from "formik"
import SubscriptionModel from "components/Common/SubscriptionModel"
import { encryptData } from "common/encrptDecryptforlogin"

let UserId = 0
function DatatableTables() {
  const { deleteApi, postApi, putApi, getApi } = useApi()
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [screeLoad, setscreeLoad] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState(0)
  const [username, setUsername] = useState("")
  const [rolename, setRolename] = useState("")
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [searchText, setSearchText] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [displayStart, setDisplayStart] = useState(1)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [displaySubAdmin, setDisplaySubAdmin] = useState(false)
  const [displayActiveUsers, setDisplayActiveUsers] = useState(true)
  const [activeInactivemodal, setActiveInactiveModal] = useState(false)
  const [inactiveRemarks, setInactiveRemarks] = useState('')
  const [isRequired, setIsRequired] = useState(false)
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [loginPassword, setLoginPasssword] = useState('')
  const [financePassword, setFinancePassword] = useState('')
  const [showPasswordModel, setShowPAsswordModel] = useState(false)

  // filterData for users
  const filterData = textFilter => {
    // setSearchText(textFilter)
  }

  const pagePerSize = (pageSize, pagePerIndex, serchValue, displaySubAdmin) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setDisplaySubAdmin(displaySubAdmin)
    setRowsPerPage(pageSize)
  }
  const userList = () => {
    setscreeLoad(true)
    postApi("User/user-list", {
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      DisplaySubAdmin: displaySubAdmin,
      displayActiveUsers: displayActiveUsers,
      sortColumn: "",
      sortOrder: "asc",
    }).then(({ data, status }) => {
      if (status === 200) {
        setTableData(data.UserList)
        setTotalRecords(data.TotalRecords)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setscreeLoad(false)
    })
  }

  useEffect(() => {
    async function UserDetails() {
      const {
        data: { RoleName },
      } = await getApi(`User/user-details?id=-1`)
      setRolename(RoleName)
    }
    UserDetails()
  }, [])

  // userList useEffect
  useEffect(() => {
    userList()
  }, [rowsPerPage, searchText, displayStart, displaySubAdmin, displayActiveUsers])


  // user delete handler
  const handleDelete = id => {
    DeleteModel.confirmDeleteInquiry({
      title: "Are you sure?",
      text: "You want to delete this User permanently? ",
      isMobile,
    }).then(response => {
      if (response == true) {
        setscreeLoad(true)
        deleteApi(`User/delete-user?id=${id}`).then(({ data, status }) => {
          if (status === 200) {
            toast.success("successfully deleted ", {
              style: {
                fontSize: "15px",
              },
            })
            setscreeLoad(false)
            const updatedArray = tableData
              .map(obj => (obj.Id !== id ? obj : null))
              .filter(Boolean)
            setTableData(updatedArray)
          } else {
            setscreeLoad(false)
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      } else {
        setscreeLoad(false)
      }
    })
  }

  // user edit handle
  const handleEdit = Id => {
    if (Id) {
      navigate(routes.userUpdate, {
        state: {
          Id,
        },
      })
    }
  }

  const activeInactiveUserApi = () => {
    const currentDate = dateFormat(new Date(), "dd/MM/yyyy");
    putApi("User/active-user",
      {
        id: parseInt(UserId),
        inactiveRemarks: `${inactiveRemarks} - ${(currentDate)}`
      }).then(({ data, status }) => {
        setformSumitLoading(false)
        if (status === 200) {
          if (inactiveRemarks) {
            setActiveInactiveModal(!activeInactivemodal)
          }
          toast.success("Sucessfully Changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
        userList()
        setInactiveRemarks('')
      })
  }

  // user active/inactive switch hanlde
  const handleSwitch = async (id, value) => {
    UserId = id
    if (value) {
      setscreeLoad(true)
      activeInactiveUserApi()
    } else {
      activeInactiveUserToggle()
    }
  }

  const activeInactiveUserToggle = () => {
    setIsRequired(false)
    setActiveInactiveModal(!activeInactivemodal)
  }

  const handleSubmitInactiveRemark = () => {
    if (inactiveRemarks != '') {
      setformSumitLoading(true)
      setIsRequired(false)
      activeInactiveUserApi()
    } else {
      setIsRequired(true)
    }
  }

  const handleCancle = () => {
    setActiveInactiveModal(false)
    setInactiveRemarks('')
    $("#IsActive_" + userId + "").prop("checked", true)
  }

  // handle user click
  const handleUserClicks = () => {
    navigate(routes.userCreate)
  }

  const handleChangePassword = userId => {
    if (userId) {
      setUserId(userId)
    }
    setModal(!modal)
    validation.handleReset()
  }

  const toggle = () => {
    setModal1(!modal1)
  }

  const handleSubscription = (Username, userId) => {
    if (Username) {
      setUsername(Username || '')
      setUserId(userId)
    }
    toggle()
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    validationSchema: Yup.object({
      NewPassword: Yup.string().required("The new password is required"),
      ConfirmPassword: Yup.string()
        .required("The confirm password is required")
        .oneOf([Yup.ref("NewPassword")], "Passwords must match"),
    }),
    onSubmit: values => {
      if (values) {
        setscreeLoad(true)
        DeleteModel.confirmDeleteInquiry({
          title: "Are you sure? ",
          text: "You want to update password this user? ",
          isMobile,
        }).then(response => {
          if (response == true) {
            putApi("User/update-password", {
              userId: userId,
              newPassword: values.ConfirmPassword,
            }).then(({ data, status }) => {
              if (status == 400) {
                toast.error(data, {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                toast.success("successfully updated", {
                  style: {
                    fontSize: "15px",
                  },
                })
                setModal(false)
              }
              setscreeLoad(false)
            })
          } else {
            setscreeLoad(false)
            setModal(true)
          }
        })
      }
    },
  })

  const handleDownloadPdf = async id => {
    setscreeLoad(true)
    const html = await UserInvoicePdf(id, getApi)

    const doc = new jsPDF({
      format: "a5",
      unit: "px",
      filters: ["ASCIIHexEncode"],
      compress: true,
    })
    // var imgData = "data:image/jpeg;base64," + imagebase64
    doc.addFileToVFS("NotoSansGujarati-Regular.ttf", UserFont)
    doc.addFont(
      "NotoSansGujarati-Regular.ttf",
      "NotoSansGujarati-Regular",
      "normal"
    )
    doc.setFont("NotoSansGujarati-Regular") // set font
    doc.html(html, {
      async callback(doc) {
        await doc.save("invode.pdf")
      },
      margin: [10, 10, 10, 10],
    })
    setscreeLoad(false)
  }

  const whatapp = (num, username, Password) => {
    window.open(
      `https://wa.me/+91${num}?text=${encodeURIComponent(`
        *Rentopus* \nUsername: ${username}\nPassword: ${Password} \nLogin URL: https://shop.whitecoretechnology.com/`
      )}`,
      "_blank",
      "width=1000,height=600,menubar=no,toolbar=no"
    );
  }

  const handleEncrptdata = async () => {
    localStorage.setItem("BacktoLogin", await encryptData(JSON.stringify({ backToLogin: true })))
  };

  const login = async (username, password) => {
    postApi("User/login", {
      username: username.trim(),
      password: password.trim(),
    }).then(async ({ data, status }) => {
      if (status == 200) {
        localStorage.setItem("authUser", JSON.stringify(data))
        localStorage.setItem("ShopName", data.ShopName)
        localStorage.setItem("FinancialYearDate", data?.FinancialYear)
        localStorage.setItem("FinancialYearFrom", data?.FinancialYearFrom)
        localStorage.setItem("FinancialYearTo", data?.FinancialYearTo)
        handleEncrptdata()
        navigate(routes.dashboard, {
          state: {
            backToLogin: true,
          },
        })
        location.reload()

      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  const PasswordShow = (loginPassword, financePassword, username) => {
    setLoginPasssword(loginPassword)
    setFinancePassword(financePassword)
    setUsername(username)
    showPasswordToggle()
  }

  const showPasswordToggle = () => {
    setShowPAsswordModel(!showPasswordModel)
  }

  const handleInputChange = (remark) => {
    setIsRequired(false)
    setInactiveRemarks(remark);
  };

  // table accessor & header
  const columns = useMemo(
    () => [
      {
        Header: "UserId",
        accessor: "Id",
        width: 5,
      },
      {
        Header: "Role",
        accessor: "RoleName",
        width: 12,
      },
      {
        Header: "Username",
        accessor: "Username",
      },
      {
        Header: "Full Name",
        accessor: "FullName",
      },
      {
        Header: "Contact No.",
        accessor: "ContactNo",
        width: "7%",
      },
      {
        Header: "Shop Name",
        accessor: "ShopName",
      },
      {
        Header: "Address",
        accessor: "Address",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Address}`}
          >
            {row.original.Address.length > 15
              ? `${row.original.Address.substr(0, 15)}...`
              : row.original.Address == ""
                ? "N/A"
                : row.original.Address}
          </p>
        ),
      },
      {
        Header: "Last Login",
        accessor: "LastLoginDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.LastLoginDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateTimeFormat(
                row.original.LastLoginDate,
                "dd/MM/yyyy HH:mm tt"
              )}
          </p>
        ),
      },
      {
        Header: "Create Date",
        accessor: "CreatedOn",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.CreatedOn == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.CreatedOn.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Active
          </div>
        ),
        width: "6%",
        accessor: "IsActive",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              id={`IsActive_${row.original.Id}`}
              type="switch"
              defaultChecked={row.original.IsActive}
              onClick={e => {
                handleSwitch(row.original.Id, e.target.checked)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: "Inactive Remark",
        accessor: "InactiveRemarks",
        width: "10%",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original?.InactiveRemarks == null ? '' : row.original?.InactiveRemarks}`}
          >
            {row.original.InactiveRemarks?.length > 15
              ? `${row.original?.InactiveRemarks.substr(0, 15)}...`
              : row.original?.InactiveRemarks == "" || row.original?.InactiveRemarks == null
                ? "N/A"
                : row.original?.InactiveRemarks}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-unlock-alt "
                  style={{ color: "#90499c", cursor: "pointer" }}
                  id={`changepasswordtooltip${row.original.Id}`}
                  onClick={() => handleChangePassword(row.original.Id)}
                ></i>
                <span className="mytext">Change Password</span>
              </li>

              {row.original.RoleName == "Vendor" && (
                <>
                  {row.original.IsSubAdmin ? <>
                    <li>
                      <i
                        className=" fs-6 fas fa-link"
                        id={`Subscribetooltip${row.original.Id}`}
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                      ></i>
                    </li>

                    <li className="mytooltip">
                      <i
                        className=" fs-6 fab fa-whatsapp"
                        id={`Subscribetooltip${row.original.Id}`}
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                      ></i>
                    </li>
                    <li className="mytooltip">
                      <i
                        className=" fs-6 fas fa-exchange-alt"
                        id={`Subscribetooltip${row.original.Id}`}
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                      ></i>
                    </li>
                  </> :
                    <>
                      {row.original.Subscribed == true ? (
                        <li>
                          <i
                            className=" fs-6 fas fa-link"
                            id={`Subscribetooltip${row.original.Id}`}
                            style={{ cursor: "not-allowed", color: "#808080b3" }}
                          ></i>
                        </li>
                      ) : (
                        <li className="mytooltip">
                          <i
                            className=" fs-6 fas fa-link"
                            style={{ color: "#556ee6", cursor: "pointer" }}
                            id={`Subscribetooltip${row.original.Id}`}
                            onClick={() =>
                              handleSubscription(
                                row.original.Username,
                                row.original.Id
                              )
                            }
                          ></i>
                          <span className="mytext">Create Subscription</span>
                        </li>
                      )}

                      <li className="mytooltip">
                        <i
                          className=" fs-6 fab fa-whatsapp"
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() =>
                            whatapp(row.original.ContactNo, row.original.Username, row.original.Password)
                          }
                        ></i>
                        <span className="mytext">WhatApp</span>
                      </li>
                      <li className="mytooltip">
                        <i
                          className=" fs-6 fas fa-exchange-alt"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() =>
                            login(row.original.Username, row.original.Password)
                          }
                        ></i>
                        <span className="mytext">Login</span>
                      </li>

                    </>}
                </>
              )}
              {row.original.RoleName == "Vendor" &&
                <li className="mytooltip">
                  <i
                    className="mdi mdi-lock-open-check"
                    style={{ fontSize: "15px", color: "#f1b44c", cursor: "pointer" }}
                    onClick={() =>
                      PasswordShow(row.original.Password, row.original.FinancePassword, row.original.Username)
                    }
                  ></i>
                  <span className="mytext">Password Show</span>
                </li>
              }
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit "
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEdit(row.original.Id)}
                ></i>
                <span className="mytext">Edit</span>
              </li>
              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData, activeInactivemodal]
  )

  // table accessor & header
  const columns1 = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "RoleName",
        width: 12,
      },
      {
        Header: "Username",
        accessor: "Username",
      },
      {
        Header: "Full Name",
        accessor: "FullName",
      },
      {
        Header: "Contact No.",
        accessor: "ContactNo",
        width: "7%",
      },
      {
        Header: "Shop Name",
        accessor: "ShopName",
      },
      {
        Header: "Address",
        accessor: "Address",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Address}`}
          >
            {row.original.Address.length > 15
              ? `${row.original.Address.substr(0, 15)}...`
              : row.original.Address == ""
                ? "N/A"
                : row.original.Address}
          </p>
        ),
      },
      {
        Header: "Last Login",
        accessor: "LastLoginDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.LastLoginDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateTimeFormat(
                row.original.LastLoginDate,
                "dd/MM/yyyy HH:mm tt"
              )}
          </p>
        ),
      },
      {
        Header: "Create Date",
        accessor: "CreatedOn",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.CreatedOn == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.CreatedOn.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-unlock-alt "
                  style={{ color: "#90499c", cursor: "pointer" }}
                  id={`changepasswordtooltip${row.original.Id}`}
                  onClick={() => handleChangePassword(row.original.Id)}
                ></i>
                <span className="mytext">Change Password</span>
              </li>

              {row.original.RoleName == "Vendor" && (
                <>
                  {row.original.IsSubAdmin ? <>
                    <li>
                      <i
                        className=" fs-6 fas fa-link"
                        id={`Subscribetooltip${row.original.Id}`}
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                      ></i>
                    </li>

                    <li className="mytooltip">
                      <i
                        className=" fs-6 fab fa-whatsapp"
                        id={`Subscribetooltip${row.original.Id}`}
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                      ></i>
                    </li>
                    <li className="mytooltip">
                      <i
                        className=" fs-6 fas fa-exchange-alt"
                        id={`Subscribetooltip${row.original.Id}`}
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                      ></i>
                    </li>
                  </> :
                    <>
                      {row.original.Subscribed == true ? (
                        <li>
                          <i
                            className=" fs-6 fas fa-link"
                            id={`Subscribetooltip${row.original.Id}`}
                            style={{ cursor: "not-allowed", color: "#808080b3" }}
                          ></i>
                        </li>
                      ) : (
                        <li className="mytooltip">
                          <i
                            className=" fs-6 fas fa-link"
                            style={{ color: "#556ee6", cursor: "pointer" }}
                            id={`Subscribetooltip${row.original.Id}`}
                            onClick={() =>
                              handleSubscription(
                                row.original.Username,
                                row.original.Id
                              )
                            }
                          ></i>
                          <span className="mytext">Create Subscription</span>
                        </li>
                      )}

                      <li className="mytooltip">
                        <i
                          className=" fs-6 fab fa-whatsapp"
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() =>
                            whatapp(row.original.ContactNo, row.original.Username, row.original.Password)
                          }
                        ></i>
                        <span className="mytext">WhatApp</span>
                      </li>
                      <li className="mytooltip">
                        <i
                          className=" fs-6 fas fa-exchange-alt"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() =>
                            login(row.original.Username, row.original.Password)
                          }
                        ></i>
                        <span className="mytext">Login</span>
                      </li>

                    </>}
                </>
              )}
              {row.original.RoleName == "Vendor" &&
                <li className="mytooltip">
                  <i
                    className="mdi mdi-lock-open-check"
                    style={{ fontSize: "15px", color: "#f1b44c", cursor: "pointer" }}
                    onClick={() =>
                      PasswordShow(row.original.Password, row.original.FinancePassword, row.original.Username)
                    }
                  ></i>
                  <span className="mytext">Password Show</span>
                </li>
              }
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit "
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEdit(row.original.Id)}
                ></i>
                <span className="mytext">Edit</span>
              </li>
              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData, activeInactivemodal]
  )

  //meta title
  document.title = `Users | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {screeLoad && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].parent}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          link={breadcrumbs[pathname].parentLink}
        />
        <Card>
          <CardBody style={{ paddingTop: "10px" }}>
            <TableContainer
              columns={JSON.parse(localStorage.getItem("authUser")).RoleName != 'Admin' ? columns1 : columns}
              data={tableData}
              isGlobalFilter={true}
              isAddUserList={true}
              handleUserClick={handleUserClicks}
              customPageSize={20}
              className="custom-header-css"
              isLoading={isLoading}
              filterData={filterData}
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              rolename={rolename}
              setDisplayActiveUsers={setDisplayActiveUsers}
              displayActiveUsers={displayActiveUsers}
            />
          </CardBody>

          <Modal
            isOpen={modal}
            toggle={() => setModal(!modal)}
            centered={true}
            size="small"
            style={{ width: "350px" }}
          >
            <div className="modal-content">
              <ModalHeader toggle={() => setModal(!modal)} tag="h5">
                Change Password
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                  onReset={e => {
                    e.preventDefault
                    validation.handleReset()
                    return false
                  }}
                >
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom03" className="required">
                      New Password
                    </Label>
                    <Input
                      name="NewPassword"
                      placeholder="New Password"
                      type="password"
                      className="form-control-sm"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.NewPassword || ""}
                      invalid={
                        validation.touched.NewPassword &&
                          validation.errors.NewPassword
                          ? true
                          : false
                      }
                    />
                    {validation.touched.NewPassword &&
                      validation.errors.NewPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.NewPassword}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <Label htmlFor="validationCustom03" className="required">
                    Confirm Password
                  </Label>
                  <InputGroup className="mb-2">
                    <Input
                      className="form-control-sm"
                      name="ConfirmPassword"
                      value={validation.values.ConfirmPassword || ""}
                      type={passwordShow ? "text" : "password"}
                      placeholder="Confirm Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.ConfirmPassword &&
                        !!validation.errors.ConfirmPassword
                      }
                    />
                    <Button
                      onClick={() => setPasswordShow(!passwordShow)}
                      className="btn btn-sm"
                      style={{ border: "1px solid #ced4da" }}
                      color="light"
                    >
                      {passwordShow ? (
                        <i className="mdi mdi-eye-outline" />
                      ) : (
                        <i className="mdi mdi-eye-off-outline" />
                      )}
                    </Button>
                    {validation.touched.ConfirmPassword &&
                      validation.errors.ConfirmPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.ConfirmPassword}
                      </FormFeedback>
                    ) : null}
                  </InputGroup>

                  <div className="text-end">
                    <>
                      <Button
                        color="primary"
                        className="btn btn-primary btn-sm "
                        type="submit"
                      >
                        Submit
                      </Button>
                    </>
                  </div>
                </Form>
              </ModalBody>
            </div>
          </Modal>

          <SubscriptionModel
            modal={modal1}
            toggle={toggle}
            userListApi={userList}
            username={username}
            subscriptionUserId={userId}
          />

          <Modal
            isOpen={activeInactivemodal}
            toggle={activeInactiveUserToggle}
            centered={true}
            size="small"
            style={{ width: "400px" }}
          >
            <div className="modal-content">
              <ModalHeader toggle={activeInactiveUserToggle} tag="h5">
                Inactive Remarks
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom03" className="required">
                      Remark
                    </Label>
                    <Input
                      name="inactiveRemark"
                      placeholder="type your remark..."
                      type="textarea"
                      rows="3"
                      className="form-control-sm"
                      onChange={(e) => { handleInputChange(e.target.value) }}
                      value={inactiveRemarks}
                      invalid={
                        isRequired
                          ? true
                          : false
                      }
                    />
                    {isRequired ? (
                      <FormFeedback type="invalid">
                        Remarks is required
                      </FormFeedback>
                    ) : null}
                  </FormGroup>

                  <div className="text-end">
                    <>
                      <Button
                        color="secondary"
                        className="btn btn-primary me-2 btn-sm "
                        onClick={() => handleCancle()}
                      >
                        Cancle
                      </Button>
                      {formSumitLoading ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                          Loading
                        </button>
                      ) : (
                        <Button
                          color="primary"
                          className="btn btn-primary btn-sm "
                          type="button"
                          onClick={() => handleSubmitInactiveRemark()}
                        >
                          Submit
                        </Button>
                      )}
                    </>
                  </div>
                </Form>
              </ModalBody>
            </div>
          </Modal>

          <Modal
            isOpen={showPasswordModel}
            toggle={showPasswordToggle}
            centered={true}
            size="small"
            style={{ width: "350px" }}
          >
            <div className="modal-content">
              <ModalHeader toggle={showPasswordToggle} tag="h5">
                Passwords
              </ModalHeader>
              <ModalBody>
                <div style={{ fontSize: "15px" }}>
                  <div className="mb-2">
                    Username:
                    <span title="copytext" className="m-2" onClick={() => navigator.clipboard.writeText(username)} >{username}</span>
                  </div>
                  <div className="mb-2">
                    Login:
                    <span title="copytext" className="m-2" onClick={() => navigator.clipboard.writeText(loginPassword)} >{loginPassword}</span>
                  </div>
                  <div>
                    Finance:
                    <span title="copytext" className="m-2" onClick={() => navigator.clipboard.writeText(financePassword || "N/A")}>{financePassword || "N/A"}</span>
                  </div>
                </div>
              </ModalBody>
            </div>
          </Modal>

        </Card>
      </div>
    </div>
  )
}

DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
