import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import Select from "react-select";
import { useState } from "react"

const colourStyles = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
  control: (styles, { isDisabled }) => {
    const updatedStyles = {
      ...styles,
      border: "1px solid  #ced4da",
      minHeight: "35px",
      padding: "3px 10px",
      fontSize: "12px",
    }
    return updatedStyles
  },
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  setGlobalFilter,
  isJobListGlobalFilter,
  value,
  setValue,
}) {
  const count = preGlobalFilteredRows.length
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col>
        <div className="search-box w-full d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Search…"}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const CustomerTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  className,
  pagePerSize,
  isLoading,
  TotalRecords,
  isRetire,
  isInStock,
  isSale,
  categoryId,
  setUserId,
  userControls,
  selectedUser, setSelectedUser,
  writeDataToExcel,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      manualPagination: true,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const [pageIndex, setpageIndex] = useState(1)
  const [prevValue, setPrevValue] = useState();

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [value, setValue] = React.useState('')
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [isIpad, setIsIpad] = useState(window.innerWidth < 924)

  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value, isRetire, isInStock, isSale, categoryId)
    setPrevValue(value);
  }, [pageSize, value, pageIndex, isRetire, isInStock, isSale, categoryId])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1);
    }
  }, [value]);

  useEffect(() => {
    setpageIndex(1);
  }, [categoryId]);

  useEffect(() => {
    if (isRetire == true) {
      setpageIndex(1);
    }
  }, [isRetire]);

  useEffect(() => {
    if (isInStock == true) {
      setpageIndex(1);
    }
  }, [isInStock]);

  useEffect(() => {
    if (isSale == true) {
      setpageIndex(1);
    }
  }, [isSale]);

  useEffect(() => {
    setpageIndex(1);
  }, [pageSize]);

  return (
    <Fragment>
      <Row >
        <Col md="12" >
          <Row>
            <Col md={2} className="table-header pe-0">
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={isIpad ? "5" : "2"} className="table-header pe-0">
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
            {JSON.parse(localStorage.getItem("authUser")).RoleName == "Admin" &&
              <>
                <Col md={isIpad ? "4" : "3"} className="table-header">
                  <Select
                    unstyled
                    styles={colourStyles}
                    value={selectedUser}
                    onChange={(e) => {
                      setSelectedUser(e)
                      setUserId(e?.value);
                    }}
                    options={userControls}
                    className="select2-selection"
                  />
                </Col>
              </>
            }
            <Col md={JSON.parse(localStorage.getItem("authUser")).RoleName == "Admin" ? 5 : 8} className="table-header" style={{ marginTop: isMobile && "8px", display: "flex", justifyContent: "end" }}>
              <div>
                {data.length > 0 ? (<button
                  type="button"
                  className="btn btn-excel  btn-label"
                  onClick={writeDataToExcel}
                >
                  Excel <i className="far fa-file-excel label-icon"></i>
                </button>) : (<button
                  type="button"
                  className="btn  btn-excel  btn-label"
                  style={{ cursor: "not-allowed" }}
                >
                  Excel <i className="far fa-file-excel label-icon"></i>
                </button>)}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="text-wrap"
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex - 1)}
              disabled={pageIndex == 1}
            >
              {"<<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page
          <strong style={{ marginLeft: "5px" }}>
            {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
          </strong> | Total <strong>{TotalRecords}</strong>
        </Col>
        {/* <Col className="col-md-auto">
          <Input
            type="text"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex}
            onChange={onChangeInInput}
            value={pageIndex}
            disabled
          />
        </Col> */}

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex + 1)}
              disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment >

  )
}

CustomerTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CustomerTable
