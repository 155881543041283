import useApi from "common/ApiMiddlewere"
import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  const { postApi } = useApi()
  const [version, setversion] = useState()

  useEffect(() => {
    postApi("Setting/setting-by-keys", {
      key: ["CURRENT_VERSION"],
      userId: 0
    }).then(({ data, status }) => {
      if (status == 200) {
        const [version] = data[0]?.Value.split('|');
        setversion(version)
        let oldVersion = localStorage.getItem("OldVersion")
        if (version != oldVersion) {
          localStorage.setItem("OldVersion", version)
          location.reload(true)
        }
      }
    })
  }, [location.pathname])

  return (
    <React.Fragment>
      <footer className="footer" style={{ fontSize: "14px" }}>
        <Container fluid={true}>
          {/* <Row>
            <Col md={6}>
              All rights reserved © 2022-{new Date().getFullYear()}   
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
               v. 1.1.1
              </div>
            </Col>
          </Row> */}

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              All rights reserved © 2022-{new Date().getFullYear()}
            </div>
            <div className="d-flex">Rentopus Version {version}</div>
            <div className="d-flex">
              Design & Develop by WhiteCore Technology LLP
            </div>
          </div>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
