import React, { useState, useEffect, useRef } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  Modal,
  ModalBody,
  FormGroup,
  FormFeedback,
  Button,
  Form,
  Label,
  Input,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
// users
import user1 from "../../../assets/user/profilepic.png"
import useApi from "common/ApiMiddlewere"
import { routes } from "routes/path"
import { toast } from "react-hot-toast"
import { useFormik } from "formik"
import DeleteModel from "common/deleteModel/deleteModel"
import { addToCartRequest, financeAmountRequest } from "store/addtocart/action"
import { encryptData, decryptData } from "common/encrptDecryptforlogin"
import { dateFormat } from "pages/Common"
import gstPercentage from "common/GetGSTper"


const ProfileMenu = () => {
  const navigate = useNavigate()
  const inputRef = useRef()
  const { getApi, postApi, putApi } = useApi()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [RoleName, setRoleName] = useState("")
  const [username, setusername] = useState("")
  const [profilePic, setprofilePic] = useState()
  const [userId, setUserId] = useState(-1)
  const dispatch = useDispatch();
  const addToCartStatus = useSelector(state => state.ReduxData.addToCartStatus == -1 ? 0 : state.ReduxData.addToCartStatus);
  const financeAmountShowHide = useSelector(state => state.ReduxData.finaceAmountShow);
  const [backtoLoginhandleState, setbacktoLoginhandleState] = useState(false)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [isFinance, setIsFinance] = useState(false)
  const [financePasswordShow, setFinancePAsswordShow] = useState(false)
  const [isTimer, setIsTimer] = useState(false)
  const [financePasswordLoading, setFinancePasswordLoading] = useState(false)
  const [changePasswordLoading, setChangePasswordLoading] = useState(false)
  const [subAdminPasswordLoading, setSubAdminPasswordLoading] = useState(false)
  const [userCode, setUserCode] = useState(0)
  const [isSubAdmin, setIsSubAdmin] = useState(JSON.parse(localStorage.getItem("authUser"))?.SubAdmin)
  const [isFocus, setIsFocus] = useState(false)
  const [enableGroomWellMenu, setEnableGroomWellMenu] = useState(false)
  const [financialYearLookup, setFinanacialYearLookup] = useState([])
  const [currentFinancialYearSelected, setCurrentFinancialYearSelected] = useState(0)

  useEffect(() => {
    getApi(`User/user-details?id=${userId}`).then(({ data, status }) => {
      if (status === 200) {
        setusername(data?.Username)
        setprofilePic(data?.ProfilePic)
        setRoleName(data?.RoleName)
        getDashboardData(data?.RoleName)
        setUserCode(dateFormat(
          data?.CreatedOn.split("T")[0],
          "ddMMyyyy"
        ) + data?.Id)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [location.pathname == "/dashboard", JSON.parse(localStorage.getItem("authUser"))?.SubAdmin == true])

  useEffect(() => {
    getDashboardData(RoleName)
  }, [location.pathname == "/cart" || location.pathname == "/booking"])

  useEffect(() => {
    if (!financeAmountShowHide && localStorage.getItem("finance") == "true" && (location.pathname == "/income-expense" || location.pathname == "/account-ledger" || location.pathname == "/pending-bills")) {
      navigate(routes.dashboard)
    }
  }, [financeAmountShowHide])

  useEffect(() => {
    if (localStorage.getItem("BacktoLogin")) {
      const handleDecrptdata = async () => {
        setbacktoLoginhandleState(JSON.parse(await decryptData(localStorage.getItem("BacktoLogin"))));
      };
      handleDecrptdata()
    }

    if (JSON.parse(localStorage.getItem("authUser")).RoleName
      != "Salesman") {
      postApi("Setting/setting-by-keys", {
        key: ["ENABLE_FINANCE", "ENABLE_GROOMWELL"],
        userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
      }).then(({ data, status }) => {
        if (status == 200) {
          setFinancePAsswordShow(data[0]?.Value == "1")
          setEnableGroomWellMenu(data[1]?.Value == "0")
          if (data[0]?.Value == "1") {
            if (localStorage.getItem("BacktoLogin")) {
              dispatch(financeAmountRequest(true));
              localStorage.removeItem("fp")
              localStorage.removeItem("finance")
            } else {
              localStorage.setItem("finance", true)
              localStorage.removeItem("fp")
            }
          } else {
            dispatch(financeAmountRequest(true));
            localStorage.removeItem("fp")
            localStorage.removeItem("finance")
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    if (enableGroomWellMenu && (location.pathname == "/export-product" || location.pathname == "/map-category" || location.pathname == "/groomwell-products" || location.pathname == "/groomwell-profile")) {
      navigate("/login")
      localStorage.clear()
    }
  }, [location.pathname, enableGroomWellMenu])

  // useEffect(() => {
  //   postApi("Reminder/list", {
  //     displayStart: 1,
  //     pageSize: 500,
  //     searchText: '',
  //     sortColumn: "",
  //     sortOrder: "",
  //   }).then(({ data, status }) => {
  //     if (status === 200) {
  //       const filterdata = data?.ReminderList?.filter(item => dateFormat(
  //         item?.ReminderDate.split("T")[0],
  //         "yyyy-MM-dd"
  //       ) == new Date().toISOString().slice(0, 10))
  //       if (filterdata.length) {
  //         setModal3(!modal3)
  //       }
  //       setReminderData(filterdata)
  //     } else {
  //       toast.error(data, {
  //         style: {
  //           fontSize: "15px",
  //         },
  //       })
  //     }
  //   })

  // }, [window.location.pathname])


  gstPercentage()
  const getDashboardData = roleName => {
    if (roleName == "Vendor" || roleName == "Admin") {
      getApi(`Dashboard/dashboard-widget`, "", RoleName).then(
        ({ data, status }) => {
          if (status === 200) {
            data.map(item => {
              if (item.Key === "TotalProductinCart") {
                dispatch(addToCartRequest(item.Value));
              }
            })
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        }
      )
    }
  }

  const handleRefresh = () => {
    getDashboardData(RoleName)
    // navigate(routes.dashboard, {
    //   state: true,
    // })
  }

  const handleCart = () => {
    navigate(routes.cartlist)
  }

  const handleChangePassword = () => {
    setIsFinance(false)
    setModal(!modal)
    validation.handleReset()
  }

  const handleChangeFinancePassword = () => {
    setIsFinance(true)
    setModal(!modal)
    validation.handleReset()
  }

  const handleShowAmount = () => {
    setModal1(!modal1)
    validation1.handleReset()
  }

  const handleHideAmount = () => {
    localStorage.setItem("finance", true);
    localStorage.removeItem("fp")
    dispatch(financeAmountRequest(false));
    toast.success("Finance mode disable", {
      style: {
        fontSize: "15px",
      },
    })
  }

  const handleUserProfile = () => {
    getApi(`User/user-details?id=${userId}`).then(({ data, status }) => {
      if (status === 200) {
        navigate(routes.profile, {
          state: {
            data,
          },
        })
      }
    })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
      isFinance: isFinance,
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().when('isFinance', {
        is: true,
        then: Yup.string().required("The old password is required"),
        otherwise: Yup.string(),
      }),
      NewPassword: Yup.string().required("The new password is required"),
      ConfirmPassword: Yup.string()
        .required("The confirm password is required")
        .oneOf([Yup.ref("NewPassword")], "Passwords must match"),
    }),
    onSubmit: values => {
      if (values) {
        setChangePasswordLoading(true)
        DeleteModel.confirmDeleteInquiry({
          title: "Are you sure? ",
          text: "You want to update password this user? ",
          isMobile,
        }).then(response => {
          if (response == true) {
            const endPoint = isFinance ? 'User/update-finance-password' : "User/update-password"
            const obj = isFinance ? {
              newPassword: values.ConfirmPassword,
              oldPassword: values.oldPassword,
            } : {
              userId: userId,
              newPassword: values.ConfirmPassword,
            }
            putApi(endPoint, obj).then(({ data, status }) => {
              if (status == 200) {
                setIsTimer(true)
                toast.success("successfully updated", {
                  style: {
                    fontSize: "15px",
                  },
                })
                setModal(false)
              } else {
                toast.error(data, {
                  style: {
                    fontSize: "15px",
                  },
                })
              }
              setChangePasswordLoading(false)
            })
          } else {
            setModal(true)
            setChangePasswordLoading(false)
          }
        })
      }
    },
  })

  const validation1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      ConfirmPassword: "",
    },
    validationSchema: Yup.object({
      ConfirmPassword: Yup.string()
        .required("The password is required")
    }),
    onSubmit: values => {
      if (values) {
        setFinancePasswordLoading(true)
        getApi(`User/verify-finance-password?password=${values.ConfirmPassword}`, "").then(
          ({ data, status }) => {
            if (status === 200) {
              setIsTimer(true)
              dispatch(financeAmountRequest(true));
              localStorage.setItem("fp", true)
              setModal1(!modal1)
              toast.success("Finance mode enable", {
                style: {
                  fontSize: "15px",
                },
              })

            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
            setFinancePasswordLoading(false)
          }
        )

      }
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isTimer) {
        dispatch(financeAmountRequest(false));
        localStorage.removeItem("fp");
        setIsTimer(false)
      }
    }, 10 * 60 * 1000);
    return () => clearTimeout(timer);
  }, [isTimer]);

  const handleEncrptdata = async () => {
    localStorage.setItem("BacktoLogin", await encryptData(JSON.stringify({ backToLogin: false })))
  };

  const backtoLogin = async () => {
    try {
      getApi(`User/user-details?id=${1}`).then(async ({ data, status }) => {
        if (status === 200) {
          postApi("User/login", {
            username: data.Username.trim(),
            password: data.Password.trim(),
          }).then(async ({ data, status }) => {
            if (status === 200) {
              localStorage.setItem("authUser", JSON.stringify(data));
              localStorage.setItem("ShopName", data.ShopName);
              localStorage.setItem("FinancialYearDate", data?.FinancialYear)
              handleEncrptdata()
              navigate(routes.dashboard);
              location.reload();
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              });
            }
          })
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCodeCopy = () => {
    // Create a temporary textarea element to copy the userCode
    const code = document.createElement('textarea');
    code.value = userCode;
    document.body.appendChild(code);

    // Select the text inside the code
    code.select();
    code.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand('copy');

    // Remove the temporary code
    document.body.removeChild(code);
  };

  const handleKeyPress = (event) => {
    if (JSON.parse(localStorage.getItem("authUser")).RoleName
      == "Vendor" && !JSON.parse(localStorage.getItem("authUser"))?.SubAdmin) {
      if (event.ctrlKey && event.shiftKey && event.key === 'F2') {
        closePopup()
        setIsFocus(true)
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [modal2]);

  // SUB ADMIN LOG OUT  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event.type === 'beforeunload') {
        if (JSON.parse(localStorage.getItem("authUser"))?.SubAdmin) {
          localStorage.removeItem("authUser");
          postApi("User/logout-subadmin", '')
            .then(({ data, status }) => {
              if (status === 200) {
                localStorage.setItem("authUser", JSON.stringify(data))
                setIsSubAdmin(JSON.parse(localStorage.getItem("authUser"))?.SubAdmin)
              } else {
              }
            })
        }
      }
    };

    getApi("Setting/financial-year-lookup").then(({ data, status }) => {
      if (status === 200) {
        setFinanacialYearLookup(data)
        data.map((item, index) => {
          if (item?.IsCurrent) {
            setCurrentFinancialYearSelected(item?.Id)
          }
        })
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  // Function to close the popup
  const closePopup = () => {
    subAdminValidation.handleReset()
    setModal2(!modal2);
  };

  const subAdminValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      Password: "",
      Username: '',
    },
    validationSchema: Yup.object({
      Username: Yup.string()
        .required("The username is required"),
      Password: Yup.string()
        .required("The password is required")
    }),
    onSubmit: values => {
      if (values) {
        setSubAdminPasswordLoading(true)
        postApi("User/login-as-subadmin", {
          username: values.Username.trim(),
          password: values.Password.trim(),
        }).then(
          ({ data, status }) => {
            if (status === 200) {
              localStorage.setItem("authUser", JSON.stringify(data))
              setIsSubAdmin(data?.SubAdmin)
              navigate(routes.dashboard)
              setModal2(!modal2)
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
            setSubAdminPasswordLoading(false)
          }
        )
      }
    },
  })

  const handleFincialYearChange = (value) => {
    postApi("Setting/change-financial-year", {
      id: value,
    }).then(({ data, status }) => {
      if (status == 200) {
        localStorage.setItem("FinancialYearDate", data?.DisplayName)
        localStorage.setItem("FinancialYearFrom", data?.StartDate)
        localStorage.setItem("FinancialYearTo", data?.EndDate)
        location.reload();
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  return (
    <React.Fragment>
      <div>
        <div style={{ gap: "15px", display: "flex" }}>
          {RoleName == "Vendor" && (
            <>
              <div id="page-header-notifications-dropdown"
                className="refresh_dashboard btn header-item noti-icon position-relative"
                style={{ paddingRight: "0px" }}
              >
                <Input
                  type="select"
                  onChange={e =>
                    handleFincialYearChange(e.target.value)
                  }
                  defaultValue={currentFinancialYearSelected}
                  className="form-control"
                >
                  {financialYearLookup.map((item, index) => {
                    const startYear = item?.DisplayName.split(" to ")[0]
                    const endYear = item?.DisplayName.split(" to ")[1]
                    return (
                      <option key={index} value={item.Id}>
                        {startYear?.split("-")[2]} -
                        {(endYear?.split("-")[2])}
                      </option>
                    )
                  })}
                </Input>
              </div>
            </>
          )}
          {(RoleName == "Admin" || RoleName == "Vendor") && (
            <div className="refresh_dashboard" onClick={() => handleRefresh()}>
              <i className="bx bx-sync refresh_icon" title="Refresh dashboard" />
            </div>
          )}
          {RoleName == "Vendor" && (
            <div
              id="page-header-notifications-dropdown"
              className="refresh_dashboard btn header-item noti-icon position-relative"
              onClick={() => handleCart()}
            >
              <i className="bx bx-cart cart_icon" title="Cart" />
              <span className="badge bg-danger rounded-pill bubble_pill">
                {addToCartStatus}
              </span>
            </div>
          )}
          {
            backtoLoginhandleState ? backtoLoginhandleState?.backToLogin && <div className="refresh_dashboard" style={{ cursor: "pointer" }} onClick={() => backtoLogin()}>
              Back To login
            </div> : null
          }

          <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
          >
            <DropdownToggle
              className="btn header-item "
              id="page-header-user-dropdown"
              tag="button"
            >
              <img
                className={`rounded-circle ${isSubAdmin ? 'header-profile-user1' : "header-profile-user"}`}
                src={profilePic ? profilePic : user1}
                alt="Header Avatar"
              />
              <span className="d-none d-xl-inline-block ms-2 me-1">
                {username}
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              <div onClick={handleUserProfile} style={{ color: "black" }}>
                <DropdownItem>
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  <span>{"Profile"}</span>
                </DropdownItem>
              </div>
              {RoleName != "Salesman" && (
                <div>
                  <div style={{ color: "black" }} onClick={handleChangePassword}>
                    <DropdownItem>
                      <i className="bx bx-lock-open font-size-16 align-middle me-1" />
                      {"Change Password"}
                    </DropdownItem>
                  </div>

                </div>
              )}

              {(RoleName == "Vendor" && financePasswordShow && !backtoLoginhandleState?.backToLogin) &&
                <>
                  {financeAmountShowHide ?
                    <div style={{ color: "black" }} >
                      <div onClick={handleHideAmount}>
                        <DropdownItem>
                          <i className="bx bx-hide font-size-16 align-middle me-1" />
                          {"Hide Finance"}
                        </DropdownItem>
                      </div>
                      <div onClick={handleChangeFinancePassword}>
                        <DropdownItem>
                          <i className="bx bx-key font-size-16 align-middle me-1" />
                          {"Change Finance Password"}
                        </DropdownItem>
                      </div>
                    </div> : <div style={{ color: "black" }} onClick={handleShowAmount}>
                      <DropdownItem>
                        <i className="bx  bx-show-alt font-size-16 align-middle me-1" />
                        {"Show Finance"}
                      </DropdownItem>
                    </div>}</>

              }
              {(RoleName == "Admin" || RoleName == "Vendor") && (
                <Link to={routes.setting} style={{ color: "black" }}>
                  <DropdownItem>
                    <i className="bx bx-wrench font-size-16 align-middle me-1" />
                    {"Settings"}
                  </DropdownItem>
                </Link>
              )}

              <DropdownItem
                onClick={handleCodeCopy}
                className="dropdown-item1" style={{ cursor: "default" }} >
                Code: {userCode}
              </DropdownItem>

              <div className="dropdown-divider" />
              <Link to="/logout" className="dropdown-item">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>{"Logout"}</span>
              </Link>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      <Modal
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => setModal2(!modal2)} tag="h5">
            {"SubAdmin Login"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                subAdminValidation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                subAdminValidation.handleReset()
                return false
              }}
            >
              <FormGroup>
                <Label htmlFor="validationCustom03" className="required">
                  Username
                </Label>
                <InputGroup className="mb-2">
                  <Input
                    innerRef={inputRef}
                    className="form-control-sm"
                    name="Username"
                    value={subAdminValidation.values.Username || ""}
                    type="text"
                    placeholder="Username"
                    // onChange={subAdminValidation.handleChange}
                    onChange={(e) => {
                      if (isFocus) {
                        inputRef.current.focus();
                        inputRef.current.select();
                        setIsFocus(false)
                      }
                      subAdminValidation.handleChange(e);
                    }}
                    onBlur={subAdminValidation.handleBlur}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        subAdminValidation.handleSubmit()
                        return false
                      }
                    }}
                    invalid={
                      subAdminValidation.touched.Username &&
                        subAdminValidation.errors.Username
                        ? true
                        : false
                    }
                  />
                  {subAdminValidation.touched.Username &&
                    subAdminValidation.errors.Username ? (
                    <FormFeedback type="invalid">
                      {subAdminValidation.errors.Username}
                    </FormFeedback>
                  ) : null}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="validationCustom03" className="required">
                  Password
                </Label>
                <InputGroup className="mb-2">
                  <Input
                    className="form-control-sm"
                    name="Password"
                    value={subAdminValidation.values.Password || ""}
                    type={passwordShow ? "text" : "password"}
                    placeholder="Password"
                    onChange={subAdminValidation.handleChange}
                    onBlur={subAdminValidation.handleBlur}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        subAdminValidation.handleSubmit()
                        return false
                      }
                    }}
                    invalid={
                      subAdminValidation.touched.Password &&
                        subAdminValidation.errors.Password
                        ? true
                        : false
                    }
                  />
                  <Button
                    onClick={() => setPasswordShow(!passwordShow)}
                    className="btn btn-sm"
                    style={{ border: "1px solid #ced4da" }}
                    color="light"
                  >
                    {passwordShow ? (
                      <i className="mdi  mdi-eye-off-outline" />
                    ) : (
                      <i className="mdi mdi-eye-outline" />
                    )}
                  </Button>
                  {subAdminValidation.touched.Password &&
                    subAdminValidation.errors.Password ? (
                    <FormFeedback type="invalid">
                      {subAdminValidation.errors.Password}
                    </FormFeedback>
                  ) : null}
                </InputGroup>
              </FormGroup>

              <div className="text-end">
                <>
                  {subAdminPasswordLoading ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                      Loading
                    </button>
                  ) : (
                    <Button
                      color="primary"
                      className="btn btn-primary btn-sm "
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        subAdminValidation.handleSubmit()
                        return false
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => setModal(!modal)} tag="h5">
            {isFinance ? "Finance Change Password" : "Change Password"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                validation.handleReset()
                return false
              }}
            >
              {isFinance && <FormGroup className="mb-2">
                <Label htmlFor="validationCustom03" className="required">
                  Old Password
                </Label>
                <Input
                  name="oldPassword"
                  placeholder="Old Password"
                  type="password"
                  className="form-control-sm"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.oldPassword || ""}
                  invalid={
                    validation.touched.oldPassword &&
                      validation.errors.oldPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.oldPassword &&
                  validation.errors.oldPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.oldPassword}
                  </FormFeedback>
                ) : null}
              </FormGroup>}

              <FormGroup className="mb-2">
                <Label htmlFor="validationCustom03" className="required">
                  New Password
                </Label>
                <Input
                  name="NewPassword"
                  placeholder="New Password"
                  type="password"
                  className="form-control-sm"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.NewPassword || ""}
                  invalid={
                    validation.touched.NewPassword &&
                      validation.errors.NewPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.NewPassword &&
                  validation.errors.NewPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.NewPassword}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <Label htmlFor="validationCustom03" className="required">
                Confirm Password
              </Label>
              <InputGroup className="mb-2">
                <Input
                  className="form-control-sm"
                  name="ConfirmPassword"
                  value={validation.values.ConfirmPassword || ""}
                  type={passwordShow ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.ConfirmPassword &&
                    !!validation.errors.ConfirmPassword
                  }
                />
                <Button
                  onClick={() => setPasswordShow(!passwordShow)}
                  className="btn btn-sm"
                  style={{ border: "1px solid #ced4da" }}
                  color="light"
                >
                  {passwordShow ? (
                    <i className="mdi  mdi-eye-off-outline" />
                  ) : (
                    <i className="mdi mdi-eye-outline" />
                  )}
                </Button>
                {validation.touched.ConfirmPassword &&
                  validation.errors.ConfirmPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.ConfirmPassword}
                  </FormFeedback>
                ) : null}
              </InputGroup>

              <div className="text-end">
                <>
                  {changePasswordLoading ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                      Loading
                    </button>
                  ) : (
                    <Button
                      color="primary"
                      className="btn btn-primary btn-sm "
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={modal1}
        toggle={() => setModal1(!modal1)}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => setModal1(!modal1)} tag="h5">
            Finance Password
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation1.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                validation1.handleReset()
                return false
              }}
            >
              <Label htmlFor="validation1Custom03" className="required">
                Password
              </Label>
              <InputGroup className="mb-2">
                <Input
                  className="form-control-sm"
                  name="ConfirmPassword"
                  value={validation1.values.ConfirmPassword || ""}
                  type={passwordShow ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  invalid={
                    validation1.touched.ConfirmPassword &&
                    !!validation1.errors.ConfirmPassword
                  }
                />
                <Button
                  onClick={() => setPasswordShow(!passwordShow)}
                  className="btn btn-sm"
                  style={{ border: "1px solid #ced4da" }}
                  color="light"
                >
                  {passwordShow ? (
                    <i className="mdi mdi-eye-off-outline" />
                  ) : (
                    <i className="mdi mdi-eye-outline" />
                  )}
                </Button>
                {validation1.touched.ConfirmPassword &&
                  validation1.errors.ConfirmPassword ? (
                  <FormFeedback type="invalid">
                    {validation1.errors.ConfirmPassword}
                  </FormFeedback>
                ) : null}
              </InputGroup>

              <div className="text-end">
                <>
                  {financePasswordLoading ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                      Loading
                    </button>
                  ) : (
                    <Button
                      color="primary"
                      className="btn btn-primary btn-sm "
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      {/* <Modal
        isOpen={modal3}
        toggle={() => setModal3(!modal3)}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <Card>
            <CardBody className="p-3">
              <div className="text-center">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light rounded-circle text-primary h1">
                    <i className="mdi mdi-reminder" style={{ fontSize: "34px" }}></i>
                  </div>
                </div>

                <Row className="justify-content-center">
                  <Col xl={10}>
                    <h4 className="text-primary">Reminder !</h4>
                    {
                      reminderData.map((item, key) => (
                        <p key={key} className="text-muted font-size-14 mb-4">
                          {item.Description}
                        </p>
                      ))
                    }
                    <div className="text-end">
                      <Button
                        color="secondary"
                        className="btn btn-primary me-2 btn-sm "
                        type="button"
                        onClick={() => setModal3(!modal3)}
                      >
                        Snooze
                      </Button>
                      <>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-sm "
                          type="submit"
                        >
                          Complete
                        </Button>
                      </>
                    </div>

                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

        </div>
      </Modal> */}

    </React.Fragment>
  )
}

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any,
// }

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile
//   return { error, success }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// )

export default ProfileMenu
