import { DeliverySlipHTML } from "./DeliverySlipHTML"
import { dateFormat } from "pages/Common"

export const DeliverySlipPDF = async (DeliveryData) => {
  // Group items by BillNo
  const groupedData = DeliveryData.reduce((acc, item) => {
    if (!acc[item.BillNo]) {
      acc[item.BillNo] = [];
    }
    acc[item.BillNo].push(item);
    return acc;
  }, {});

  const deliverySlips = [];
  var bagNo = 0;

  for (const [billNo, items] of Object.entries(groupedData)) {
    let productCodesHtml = "";
    let slipHtml = "";
    let productCodeCount = 0;

    for (const item of items) {
      const productCodeHtml = `<div>${item.ProductCode} ${item.DyanamicData == '<i></i>' ? "" : `(${item.DyanamicData} ${item.Remarks})`}</div>`;
      productCodesHtml += productCodeHtml;
      productCodeCount++;

      if (productCodeCount === 5) {
        const firstItem = items[0];
        const remarksHtml = item.BillRemarks ? `
          <tr>
            <td style="border-top-style: solid !important; border-top-width: thin !important; padding-right: 3px;" colspan="2">Remarks: ${item.BillRemarks}</td>
          </tr>` : '';

        var htmlNew = DeliverySlipHTML;
        slipHtml = htmlNew
          .replace("{NAME}", firstItem?.Name || "N/A")
          .replace("{BILL_NO}", billNo)
          .replace("{BAG_NO}", bagNo = bagNo + 1)
          .replace(
            "{DELIVERY_DATE}",
            dateFormat(firstItem?.DeliveryDate?.split("T")[0], "dd/MM/yyyy") || "N/A"
          )
          .replace(
            "{RETURN_DATE}",
            dateFormat(firstItem?.ReturnDate?.split("T")[0], "dd/MM/yyyy") || "N/A"
          )
          .replace("{PRODUCT_CODE}", productCodesHtml)
          .replace("{BILL_REMARKS}", remarksHtml);

        deliverySlips.push(slipHtml);

        productCodesHtml = "";
        productCodeCount = 0;
      }
    }

    if (productCodeCount > 0) {
      const firstItem = items[0];

      const remarksHtml = firstItem.BillRemarks ? `
        <tr>
          <td style="border-top-style: solid !important; border-top-width: thin !important; padding-right: 3px;" colspan="2">Remarks: ${firstItem.BillRemarks}</td>
        </tr>` : '';

      var htmlNew = DeliverySlipHTML;
      slipHtml = htmlNew
        .replace("{NAME}", firstItem?.Name || "N/A")
        .replace("{BILL_NO}", billNo)
        .replace("{BAG_NO}", bagNo = bagNo + 1)
        .replace(
          "{DELIVERY_DATE}",
          dateFormat(firstItem?.DeliveryDate?.split("T")[0], "dd/MM/yyyy") || "N/A"
        )
        .replace(
          "{RETURN_DATE}",
          dateFormat(firstItem?.ReturnDate?.split("T")[0], "dd/MM/yyyy") || "N/A"
        )
        .replace("{PRODUCT_CODE}", productCodesHtml)
        .replace("{BILL_REMARKS}", remarksHtml);

      deliverySlips.push(slipHtml);
    }
  }

  const rows = [];
  for (let i = 0; i < deliverySlips.length; i += 3) {
    const row = deliverySlips.slice(i, i + 3).map(slip => `<td>${slip}</td>`).join('');
    rows.push(`<tr>${row}</tr>`);
  }

  const finalHtml = `
    <table style="font-size: 8px; width: 427px !important; height: 0px !important;">
      ${rows.join("\n")}
    </table>
  `;
  return finalHtml;
};

