import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import { dateFormat } from "pages/Common"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import CommonModel from "pages/Transiction/CommonModel"
import PendingBillTable from "./PendingBillsTable"
import { useSelector } from "react-redux"

function PendingBills() {
    let { pathname } = useLocation()
    const { postApi } = useApi()
    const financeAmountShowHide = useSelector(state => state.ReduxData.finaceAmountShow);

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [searchText, setSearchText] = useState("")
    const [displayStart, setDisplayStart] = useState(1)
    const [TotalRecords, setTotalRecords] = useState(0.0)
    const [dateDisble, setdateDisble] = useState(true)
    const { state } = useLocation();
    const [date, setdate] = useState(state == null ? [new Date(), new Date()] : [new Date(state.fromData), new Date(state.todate)])
    const [bookingId, setBookingId] = useState()
    const [modal, setModal] = useState(false)
    const [totalBillAmount, setTotalBillAmount] = useState(0)
    const [totalPendingAmount, setTotalPendingAmount] = useState(0)
    const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", bookingItemId: "", returned: false })
    const [flag, setFlag] = useState('')
    const [CommonModelFlag, setCommonModelFlag] = useState(false)

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    // return api useEffect
    useEffect(() => {
        if (date[1] != undefined) {
            pendingBillsApi()
        }
    }, [date, rowsPerPage, searchText, displayStart, dateDisble, CommonModelFlag])

    // pendingBills api
    const pendingBillsApi = () => {
        setIsLoading(true)
        postApi("Report/pending-bills", {
            fromDate: dateDisble ? dateFormat(date[0], "yyyy-MM-dd") : null,
            toDate: dateDisble ? dateFormat(date[1], "yyyy-MM-dd") : null,
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.PendingBillsList)
                setTotalRecords(data.TotalRecords)
                setTotalBillAmount(data.TotalBillAmount)
                setTotalPendingAmount(data.TotalPendingAmount)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    const toggle = () => {
        setModal(!modal)
    }

    const handleViewBookingDetails = (bookingId) => {
        setBookingId(bookingId)
        toggle()
    }

    const handleSettleModel = (bookingId) => {
        toggleCommon()
        setFlag("settle")
        setBookingIdandStatusId({...BookingIdandStatusId, bookingId: bookingId, statusId: 6 })
    }

    const toggleCommon = () => {
        setCommonModelFlag(!CommonModelFlag)
    }

    // header and accessor
    const columns = useMemo(
        () => [

            {
                Header: "Bill No.",
                accessor: "BillNo",
                width: "5%",
                Cell: ({ row }) => (
                    <>
                        {row.original.TotalProduct == 0 ? (
                            <div>{row.original.BillNo}</div>
                        ) : (
                            <div
                                className="text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleViewBookingDetails(row.original.BookingId)
                                }}
                            >
                                {row.original.BillNo}
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Customer No.",
                accessor: "ContactNo1",
                width: "7%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Bill Amt.
                    </div>
                ),
                accessor: "BillAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.BillAmount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Pending Amt.
                    </div>
                ),
                accessor: "PendingAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.PendingAmount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Status",
                width: "8%",
                accessor: "MinStatus",
                Cell: ({ row }) => {
                    return (
                        <span>
                            {row.original.MinStatus == 1 ? "Booked" : row.original.MinStatus == 2 ? "Delivered" : row.original.MinStatus == 3 ? "Return" : "Cancel"}
                        </span>
                    )
                },
            },
            {
                Header: "Return ",
                accessor: "ReturnDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ReturnDate == null
                            ? "N/A"
                            : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
                        {row.original.ReturnTimeSlot ? (
                            <span> {` (${row.original.ReturnTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Action",
                accessor: "Action",
                width: "5%",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className="fas fa-balance-scale"
                                    style={{ cursor: "pointer", color: "#90499c" }}
                                    onClick={() => handleSettleModel(row.original.BookingId, row.original.BillNo)}
                                ></i>
                                <span className="mytext">Settlement</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )

    document.title = `Pending Bills | ${localStorage.getItem("ShopName")}`

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <PendingBillTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isLoading={isLoading}
                            className="custom-header-css"
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                            dateDisble={dateDisble}
                            setdateDisble={setdateDisble}
                            date={date}
                            setdate={setdate}
                            financeAmountShowHide={financeAmountShowHide}
                            totalPendingAmount={totalPendingAmount}
                            totalBillAmount={totalBillAmount}
                        />
                    </CardBody>

                    <InvoiceDetalis
                        toggle={toggle}
                        modal={modal}
                        bookingId={bookingId}
                    />

                    {CommonModelFlag &&
                        <CommonModel
                            modal={CommonModelFlag}
                            toggle={toggleCommon}
                            BookingandStatus={BookingIdandStatusId}
                            flag={flag}
                        />
                    }

                </Card>
            </div>
        </div>
    )
}
PendingBills.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default PendingBills
