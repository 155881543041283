import { useLocation } from 'react-router-dom'
import Compressor from "compressorjs"
import breadcrumbs from 'common/Breadcrumbs'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import useApi from 'common/ApiMiddlewere'
import noImage from "../../assets/images/no_image.jpg"
import { toast } from 'react-hot-toast'
import Slider from "react-slick";
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dropzone from 'react-dropzone'
import ImageCrop from './ImageCrop'


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const ProductCatalogue = () => {
    const { getApi, postApi } = useApi()
    let { pathname } = useLocation()
    const [screeLoad, setscreeLoad] = useState(false)
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState(0)
    const [catalogueData, setCatalogueData] = useState([])
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [openImage, setOpenImage] = useState()
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [pagesize, setPagesize] = useState(12)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [isLoading, setIsLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [cropImage, setCropImage] = useState(null)
    const [isIpad, setIsIpad] = useState(window.innerWidth < 924)
    const [modal, setModal] = useState(false)
    const [productId, setProductId] = useState(0)
    const [oldImage, setOldImage] = useState('')
    const [isFullScreen, setIsFullScreen] = useState(false);

    const margin = {
        marginTop: isMobile ? "8px" : "21px",
    }

    const toggle = () => {
        setModal(!modal)
    }

    const handleImageClick = (index, code) => {
        setActiveImageIndex(index);
        setIsSliderOpen(true);
        setOpenImage(index)
    };

    const handleSliderClose = () => {
        setIsSliderOpen(false);
    };

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => setActiveImageIndex(index),
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const handlePagesize = () => {
        setPagesize(pagesize + 12)
    }

    useEffect(() => {
        setscreeLoad(true)
        getApi("Category/category-lookup").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    Key: 0,
                    Value: "All"
                });
                setCategories(data)
                setCategoryId(data[0]?.Key)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })
    }, [])

    const productCatalogueApi = () => {
        setIsLoading(true)
        setscreeLoad(true)
        postApi("Product/product-gallery", {
            categoryId: categoryId,
            displayStart: 1,
            pageSize: pagesize,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setCatalogueData(data.ProductList)
                setTotalRecords(data.TotalRecords)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        productCatalogueApi()
    }, [categoryId, pagesize, searchText])

    useEffect(() => {
        if (activeImageIndex == (catalogueData.length - 1)) {
            setPagesize(pagesize + 12)
        }
    }, [activeImageIndex])

    const handleImageDoubleClick = () => {
        setIsFullScreen(!isFullScreen);
    };

    const FullScreenImageModal = ({ image, onClose }) => {
        return ReactDOM.createPortal(
            <div className={`full-screen-modal-overlay ${isFullScreen ? 'fullscreen' : ''}`}
                // onClick={handleImageClick1}
                onDoubleClick={handleImageDoubleClick}
            >
                <div className="full-screen-modal-content">
                    <button className="close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                    <Slider {...sliderSettings} initialSlide={activeImageIndex}>
                        {catalogueData.map((data, key) =>
                        (
                            <>
                                <div className="mt-3 text-center" style={{ position: "relative", top: "0", right: "0", color: "white", display: "flex", justifyContent: "center", backgroundColor: "" }}>
                                    <h5 className="my-0" style={{ display: "flex", justifyContent: "center" }}>
                                        <span style={{ fontSize: "25px", backgroundColor: "" }}>{data?.Code}</span>
                                    </h5>
                                </div>
                                <div key={`fullscreen-slider-item-${key}`} className={`d-flex justify-content-center ${isFullScreen ? '' : ''}`}>
                                    <div style={{
                                        width: isMobile ? "300px" : "800px",
                                        height: isMobile ? "300px" : "550px",
                                    }}>
                                        <img
                                            src={data?.Image ? data.Image : noImage}
                                            alt={`Product Image ${key}`}
                                            style={{
                                                width: isMobile ? "300px" : isFullScreen ? "800px" : "800px",
                                                height: isMobile ? "300px" : isFullScreen ? "800px" : "800px",
                                                alignItems: "center",
                                                objectFit: "contain",
                                            }}

                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                    </Slider>
                </div>
            </div>,
            document.body
        );
    };

    function handleAcceptedFiles(acceptedFiles, productId, image) {
        const selectedFile = acceptedFiles[0];
        new Compressor(selectedFile, {
            quality: 0.1,
            success: async (compressedResult) => {
                setCropImage(null);
                setCropImage(URL.createObjectURL(compressedResult));
                setOldImage(image)
                if (productId) {
                    setProductId(productId)
                    toggle()
                }
            },
        });
    }

    document.title = `Product Catelogue | ${localStorage.getItem("ShopName")}`
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    {screeLoad && (
                        <div className="loading-overlay is-active">
                            <span className="fas fa-spinner fa-3x fa-spin"></span>
                        </div>
                    )}
                    <Breadcrumbs
                        parent={breadcrumbs[pathname].parent}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                        parentLink={breadcrumbs[pathname].parentLink}
                    />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={isIpad ? "3" : "3"}>
                                    <div className="mb-2">
                                        <FormGroup className="mb-2">
                                            <Label
                                                htmlFor=""
                                                className="required"
                                            >
                                                Category
                                            </Label>
                                            <Input
                                                type="select"
                                                id="validationCustom02"
                                                className="form-control"
                                                name="Category"
                                                onChange={e => setCategoryId(e.target.value, setPagesize(12))}
                                                value={categoryId}
                                            >
                                                {categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.Key}>
                                                            {item.Value}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={isIpad ? "4" : "3"}>
                                    <div className="mb-2" style={margin}>
                                        <FormGroup className="mb-2">
                                            <input
                                                onChange={e => {
                                                    setSearchText(e.target.value)
                                                }}
                                                id="search-bar-0"
                                                type="text"
                                                className="form-control"
                                                placeholder={"Search Code…"}
                                                value={searchText || ""}
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            {catalogueData.length ?
                                <div>
                                    <Row>
                                        {catalogueData?.map((data, key) => (
                                            <Col xl={3} sm={6} key={"_col_" + key}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="product-img position-relative"
                                                            onClick={() => handleImageClick(key)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src={data?.Image ? data.Image : noImage}
                                                                alt={noImage}
                                                                style={{ objectFit: "cover", height: "320px", width: "250px", textAlign: "center", alignItems: "center" }}
                                                                className="img-fluid d-block"
                                                            />
                                                        </div>
                                                        <div className="mt-3 text-center d-flex justify-content-center gap-3">
                                                            <div style={{ marginTop: "5px" }}>
                                                                <h5 className="">
                                                                    <span className='mt-2'>  <b>{data?.Code}</b></span>
                                                                </h5>
                                                            </div>
                                                            <div>
                                                                <Dropzone
                                                                    onDrop={acceptedFiles => {
                                                                        handleAcceptedFiles(acceptedFiles, data.ProductId, data?.Image)
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="">
                                                                            <div
                                                                                className=""
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} />
                                                                                <div className="">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        className="btn btn-primary btn-sm"
                                                                                        type="button"
                                                                                    >
                                                                                        {data?.Image ? "Change" : "Upload"}
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                    {isSliderOpen && (
                                        <FullScreenImageModal image={openImage} onClose={handleSliderClose} />
                                    )}
                                    {catalogueData.length != totalRecords && <Row>
                                        <Col md="12" >
                                            {isLoading ?
                                                <button type="button" className="btn btn-primary" style={{ width: "100%" }}>
                                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                    Loading
                                                </button>
                                                :
                                                <Button
                                                    color="primary"
                                                    className="btn btn-primary "
                                                    type="button"
                                                    onClick={handlePagesize}
                                                    style={{ width: "100%" }}
                                                >
                                                    Load more
                                                </Button>
                                            }
                                        </Col>
                                    </Row>}
                                </div>
                                :
                                <Row>
                                    <Col md="12">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {
                                                "No Record Found"
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </CardBody>

                        <Modal
                            isOpen={modal}
                            toggle={toggle}
                            centered={true}
                            size="default"
                        >
                            <div className="modal-content">
                                <ModalHeader toggle={toggle} tag="h5">
                                    {"Crop Image"}
                                </ModalHeader>
                                <ModalBody style={{ height: "490px" }}>
                                    <ImageCrop
                                        image={cropImage}
                                        toggle={toggle}
                                        productId={productId}
                                        oldImage={oldImage}
                                        productCatalogueApi={productCatalogueApi}
                                    />
                                </ModalBody>
                            </div>
                        </Modal>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProductCatalogue