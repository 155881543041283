import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Button,
    Card,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap"
import { dateFormat, dateTimeFormat } from "pages/Common"
import toast from "react-hot-toast"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation, useNavigate } from "react-router-dom"
import useApi from "common/ApiMiddlewere"
import CreditNotesTable from "./CreditNotesTable"
import { routes } from "routes/path"
import { exportDataExcel } from "pages/ExportData"
import ErrorModel from "common/errorModel/errorModel"
import DeleteModel from "common/deleteModel/deleteModel"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import { useFormik } from "formik"
import * as Yup from "yup"

function CreditNotes() {
    document.title = `CreditNotes | ${localStorage.getItem("ShopName")}`
    const { postApi, putApi, deleteApi } = useApi()
    let { pathname } = useLocation()
    const navigate = useNavigate()
    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [TotalRecords, setTotalRecords] = useState(0.0)
    const [dateDisble, setdateDisble] = useState(true)
    const [date, setdate] = useState([new Date(), new Date()])
    const [pageSize, setpageSize] = useState(20)
    const [displayStart, setDisplayStart] = useState(1)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [searchText, setSearchText] = useState("")
    const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", bookingItemId: "", returned: false })
    const [modal1, setModal1] = useState(false)
    const [modal, setModal] = useState(false)
    const [creditNoteId, setCreditNoteId] = useState(0)
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [isSettle, setIsSettle] = useState(false)

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setpageSize(pageSize)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
    }

    const creditNotesApi = () => {
        setIsLoading(true)
        postApi("CreditNote/list", {
            fromDate: dateDisble ? null : dateFormat(date[0], "yyyy-MM-dd"),
            toDate: dateDisble ? null : dateFormat(date[1], "yyyy-MM-dd"),
            isSettle: isSettle,
            displayStart: displayStart,
            pageSize: pageSize,
            searchText: searchText,
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data?.CreditNoteList)
                // setTableData(data?.Saleslist)
                setTotalRecords(data?.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // credit note list API useEffect
    useEffect(() => {
        if (date[1] != undefined) {
            creditNotesApi()
        }
    }, [
        date,
        isSettle,
        searchText,
        displayStart,
        dateDisble,
        pageSize,
    ])

    const handleCreateCreditNote = () => {
        navigate(routes.CreateCreditNote)
    }

    const handleDownload = async (id, Billno) => {
        setIsLoading(true)
        getApi(`Report/credit-note-print?creditNoteId=${id}`).then(({ data, status }) => {
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `invoice_${Billno}.pdf`;
            link.click();
            window.URL.revokeObjectURL(link.href);
            setIsLoading(false)
        })
    };

    const handlePrint = async (id) => {
        setIsLoading(true)
        getApi(`Report/credit-note-print?creditNoteId=${id}`).then(({ data, status }) => {
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url);

            setIsLoading(false)
            newWindow.addEventListener('load', () => {
                newWindow.print();
            });
        })
    };

    function writeDataToExcel() {
        setIsLoading(true)
        let header = ["Note No.", "Bill No.", "Name", "Customer No.", "Date", "Rent", "GST Amt.",];
        const mainTitle = "Credit Notes";
        const widthObj = [{
            Key: "A",
            Value: 12,
        },
        {
            Key: "B",
            Value: 12,
        },
        {
            Key: "C",
            Value: 25,
        },
        {
            Key: "D",
            Value: 13,
        },
        {
            Key: "E",
            Value: 10,
        },
        {
            Key: "F",
            Value: 10,
        },
        {
            Key: "G",
            Value: 12,
        },
        ]
        const extention = "Credit_Notes.xlsx"
        postApi("CreditNote/list", {
            fromDate: dateDisble ? null : dateFormat(date[0], "yyyy-MM-dd"),
            toDate: dateDisble ? null : dateFormat(date[1], "yyyy-MM-dd"),
            displayStart: 1,
            pageSize: 10000,
            searchText: searchText,
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTotalRecords(data.TotalRecords)
                let excelSheetData = []
                for (let index = 0; index < data.CreditNoteList.length; index++) {
                    let dataObj = {
                        NoteNo: data.CreditNoteList[index].NoteNo,
                        BillNo: data.CreditNoteList[index].BillNo,
                        Name: data.CreditNoteList[index].Name,
                        ContactNo: data.CreditNoteList[index].ContactNo,
                        Date: dateFormat(data.CreditNoteList[index].Date.split("T")[0],
                            "dd/MM/yyyy"),
                        NetAmount: data.CreditNoteList[index]?.NetAmount?.toFixed(2),
                        GSTAmount: data.CreditNoteList[index]?.GSTAmount?.toFixed(2),
                    }
                    excelSheetData.push(dataObj)
                }
                var fields = Object.keys(excelSheetData[0]);
                var sheetData = excelSheetData.map(function (row) {
                    return fields.map(function (fieldName) {
                        return row[fieldName];
                    });
                });
                sheetData.unshift(header);
                exportDataExcel(sheetData, mainTitle, widthObj, extention, 0, 0, 0, 0, 0, 0)

            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // Delete credit note handler
    const handleDelete = Id => {
        setIsLoading(true)
        if (Id) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this credit note permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`CreditNote/delete?id=${Id}`).then(
                        async ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== Id ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("Sucessfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Credit Note",
                                    text: data,
                                })
                            }
                            setIsLoading(false)
                        }
                    )
                } else {
                    setIsLoading(false)
                }
            })
        }
    }

    // handlers
    const toggle1 = () => {
        setModal1(!modal1)
    }

    // handlers
    const toggle = () => {
        setModal(!modal)
    }

    // view booking
    const handleViewBooking = bookingId => {
        setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
        toggle1()
    }

    // Settle  credi note  
    const settleCreditNote = noteId => {
        setCreditNoteId(noteId)
        toggle()
    }

    // Credit note table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Note No.",
                accessor: "NoteNo",
                width: "5%",
            },
            {
                Header: "Bill No.",
                accessor: "BillNo",
                width: "4%",
                Cell: ({ row }) => {
                    return <>
                        {row.original.TotalProduct == 0 ? (
                            <div style={{ color: "#E94560" }} >{row.original.BillNo}</div>
                        ) : (
                            <div
                                style={{ cursor: "pointer", color: "#556ee6" }}
                                onClick={() => {
                                    handleViewBooking(row.original.BookingId)
                                }}
                            >
                                {row.original.BillNo}
                            </div>
                        )}
                    </>
                },
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Customer No.",
                accessor: "ContactNo1",
                width: "6%",
            },
            {
                Header: "Remarks",
                accessor: "Remarks",
                width: "7%",
                Cell: ({ row }) => (
                    <p
                        className="mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original?.Remarks == null ? '' : row.original?.Remarks}`}
                    >
                        {row.original.Remarks?.length > 15
                            ? `${row.original?.Remarks.substr(0, 15)}...`
                            : row.original?.Remarks == ""
                                ? "N/A"
                                : row.original?.Remarks}
                    </p>
                ),
            },
            {
                Header: "Date",
                accessor: "CreatedOn",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.CreatedOn == ""
                            ? "N/A"
                            : dateTimeFormat(
                                row.original.CreatedOn,
                                "dd/MM/yyyy HH:mm tt"
                            )}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Amount
                    </div>
                ),
                accessor: "Amount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(Math.round(row?.original?.Amount) || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "8%",
                accessor: "Action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <>
                            {
                                (
                                    <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                                        {row?.original?.IsSettle
                                            ?
                                            (<>
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary btn-sm "
                                                    disabled
                                                    type="button"
                                                >
                                                    Settle
                                                </Button>
                                            </>) :
                                            (
                                                <>
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-sm "
                                                        type="button"
                                                        onClick={() => settleCreditNote(row?.original?.Id)}
                                                    >
                                                        Settle
                                                    </Button>
                                                </>
                                            )
                                        }

                                    </ul >
                                )
                            }
                        </>
                    )
                },
            },

        ],
        [tableData]
    )


    const validation = useFormik({
        validateOnChange: false,
        enableReinitialize: true,
        initialValues: {
            remarks: "",
        },
        validationSchema: Yup.object({
            remarks: Yup.string().required("The remarks is required"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (values) {
                putApi("CreditNote/settle", {
                    id: creditNoteId,
                    remarks: values.remarks,
                }).then(({ data, status }) => {
                    setformSumitLoading(false)
                    if (status == 200) {
                        toast.success("Sucessfully settled", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        toggle()
                        creditNotesApi()
                        validation.handleReset()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })

                    }
                })
            }
        },
    })

    return (
        <div className="page-content background">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <CreditNotesTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            handleUserClick={handleCreateCreditNote}
                            customPageSize={20}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                            className="custom-header-css"
                            dateDisble={dateDisble}
                            setdateDisble={setdateDisble}
                            date={date}
                            setdate={setdate}
                            writeDataToExcel={writeDataToExcel}
                            isSettle={isSettle}
                            setIsSettle={setIsSettle}
                        />

                        <InvoiceDetalis
                            toggle={toggle1}
                            modal={modal1}
                            bookingId={BookingIdandStatusId.bookingId}
                        />


                        <Modal
                            isOpen={modal}
                            toggle={toggle}
                            centered={true}
                            size="small"
                            style={{ width: "400px" }}
                        >
                            <div className="modal-content">
                                <ModalHeader toggle={toggle} tag="h5">
                                    Settle CreditNote
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                        onReset={e => {
                                            e.preventDefault
                                            validation.handleReset()
                                            return false
                                        }}
                                    >
                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03" className="required">
                                                Remarks
                                            </Label>
                                            <Input
                                                name="remarks"
                                                placeholder="Enter your remarks"
                                                type="textarea"
                                                rows="4"
                                                className="form-control-sm"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.remarks || ""}
                                                invalid={
                                                    validation.touched.remarks && validation.errors.remarks
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.remarks && validation.errors.remarks ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.remarks}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>

                                        <div className="text-end">
                                            <Button
                                                color="secondary"
                                                className="btn btn-primary me-2 btn-sm "
                                                type="reset"
                                            >
                                                Reset
                                            </Button>
                                            {formSumitLoading ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                    Loading
                                                </button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    className="btn btn-primary btn-sm "
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                </ModalBody>
                            </div>
                        </Modal>

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
CreditNotes.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default CreditNotes