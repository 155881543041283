export const routes = {
  index: "/",
  dashboard: "/dashboard",
  userList: "/user",
  userCreate: "/user/create",
  userUpdate: "/user/update",
  inquiries: "/inquiries",
  size: "/size",
  color: "/color",
  timeslot: "/timeslot",
  plan: "/plan",
  available: "/available",
  demo: "/demo",
  plans: "/plans",
  decryptData: "/decryptData",
  productList: "/products",
  createProduct: "/create-product",
  updateproduct: "/update-product",
  bookingList: "/booking",
  HoldBillList: "/hold-bills",
  createbooking: "/create-booking",
  updatebooking: "/update-booking",
  reportBooking: "/report-booking",
  delivery: "/delivery",
  return: "/return",
  feedback: "/feedback",
  finance: "/finance",
  setting: "/setting",
  invoice: "/invoice",
  subscription: "/subscription",
  categories: "/categories",
  profile: "/user-profile",
  viewBooking: "/view-booking",
  importProduct: "/import-product",
  cartlist: "/cart",
  productHistory: "/product-history",
  salesman: "/salesman-report",
  BookedOrderReport: "/bookedOrder-report",
  expenses: "/expense",
  income: "/income",
  incomeExpense: "/income-expense",
  decrypt: "/decrypt",
  createSale: "/create-sale",
  updateSale: "/update-sale",
  salesList: "/sales",
  catalogue: "/product-catalogue",
  tutorial: "/tutorial",
  tutorialForVendor: "/tutorials",
  reminder: "/reminder",
  releseNote: "/whats-new",
  pendingBills: "/pending-bills",
  accessoryReport: "/accessories-report",
  security: "/security",
  accounts: "/accounts",
  items: "/items",
  journalVouchers: "/journal-Vouchers",
  purchaseList: "/purchase",
  createPurchase: "/create-purchase",
  UpdatePurchase: "/update-purchase",
  accessory: "/accessory-value",
  stockRegister: "/stock-register",
  CreditNote: "/credit-note",
  CreateCreditNote: "/create-creditNote",
  accountLedger: "/account-ledger",
  exportProduct: "/export-product",
  mapCategory: "/map-category",
  groomWellProduct: "/groomwell-products",
  groomWellProfile: "/groomwell-profile",
  dbBackup: "/db-backup",
  productAvailable: "/product-available",
  salesGSTReport: "/sales-report",
  washing: "/washing",
  customers: "/customers",
  systemLog: "/system-log",
}
