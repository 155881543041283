import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useExpanded,
  usePagination,
} from "react-table"
import { Table } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"



const BookingItemTableList = ({
  columns,
  data,
  className,
  isLoading,
  status,
  BookingandStatus
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 20000,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )

  const handlesort = column => {
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  return (
    <Fragment>
      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width, padding: "9px" },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                const { Status } = row.original
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr
                      className={`${(parseInt(status) == Status || Status == 3 || Status == 4 || (BookingandStatus?.returned && Status == 1)) ? "disabled-row" : ""} text-nowrap`}
                    >
                      {row.cells.map(cell => {
                        const { Status } = cell.row.original
                        return (
                          <td
                            key={cell.id}
                            className="text-nowrap"
                            {...cell.getCellProps({ style: { minWidth: cell.minWidth, maxWidth: cell.maxWidth, width: cell.width, padding: "9px" }, })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? ("Loading...") : ("No Record Found")}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </Fragment>
  )
}

BookingItemTableList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default BookingItemTableList
