import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody, Input } from "reactstrap"
import GetObjectsByValues from "_mock/Static_data"
import ReturnTable from "./ReturnTable"
import { dateFormat } from "pages/Common"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import CommonModel from "pages/Transiction/CommonModel"

function Return() {
  let { pathname } = useLocation()
  const { postApi, putApi, getApi } = useApi()

  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [displayStart, setDisplayStart] = useState(1)
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [dateDisble, setdateDisble] = useState(true)
  const { state } = useLocation();
  const [date, setdate] = useState(state == null ? [new Date(), new Date()] : [new Date(state.fromData), new Date(state.todate)])
  const [statusCancel, setStatusCancel] = useState(false)
  const [bookingId, setBookingId] = useState()
  const [modal, setModal] = useState(false)
  const [categories, setCategories] = useState([])
  const [categoryId, setCategoryId] = useState(0)
  const [isGroupedData, setIsGroupedData] = useState(false)

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }

  useEffect(() => {
    getApi("Category/category-lookup").then(({ data, status }) => {
      if (status === 200) {
        data.unshift({
          Value: "Categories",
          Key: 0
        });
        setCategories(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setIsLoading(false)
    })
  }, [])

  // return api useEffect
  useEffect(() => {
    if (date[1] != undefined) {
      returnApi()
    }
  }, [date, rowsPerPage, searchText, displayStart, dateDisble, statusCancel, categoryId, isGroupedData])

  // returnList api
  const returnApi = () => {
    setIsLoading(true)
    postApi("Report/return-report", {
      fromDate: dateDisble ? dateFormat(date[0], "yyyy-MM-dd") : null,
      toDate: dateDisble ? dateFormat(date[1], "yyyy-MM-dd") : null,
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
      categoryId: categoryId,
      isGroupedData: isGroupedData
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.ReturnReportList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }
  // handlers
  const toggle = () => {
    setModal(!modal)
  }

  const bookingItemChangeStatus = (bookingItemId, statusId) => {
    const bookingId = ""
    putApi("Booking/change-Status", {
      bookingId: bookingId == "" ? 0 : bookingId,
      BookingItemIds: bookingItemId,
      BookingStatus: parseInt(statusId),
      returnRemarks: ''
    }).then(({ data, status }) => {
      if (status === 200) {
        returnApi()
        toast.success("Status change sucessfully", {
          style: {
            fontSize: "15px",
          },
        })
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  /// Status Change Code ///////////////////////////////////////
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", bookingItemId: "", returned: false })
  const [DeliveryModelFlag, setDeliveryModelFlag] = useState(false)

  const toggleDelivery = () => {
    if (DeliveryModelFlag) {
      setStatusCancel(true)
    }
    else {
      setStatusCancel(false)
    }
    setDeliveryModelFlag(!DeliveryModelFlag)
  }


  const handleProductStatus = (bookingItemId, statusId, bookingId) => {
    // setIsLoading(true)
    if (statusId == 3) {
      // setIsLoading(true)
      toggleDelivery()
      setBookingIdandStatusId({ bookingId: bookingId, statusId: statusId, bookingItemId: bookingItemId, returned: true })
    }
    else if ((bookingItemId, statusId)) {
      bookingItemChangeStatus(bookingItemId, statusId)
    }

  }

  ///////////////////////////////////////////////////////
  const handleViewBookingDetails = (bookingId) => {
    setBookingId(bookingId)
    toggle()
  }

  // header and accessor
  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "Action",
        // width: "6%",
        Cell: ({ row }) => {
          const { Status } = row.original;
          let obj;

          if (Status === 2) {
            obj = GetObjectsByValues(2, 3);
          } else if (Status === 3) {
            obj = GetObjectsByValues(3);
          }
          return <div
            className="textWrap"
            style={{
              height: "8px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Input
              type="select"
              onChange={e =>
                handleProductStatus(row.original.BookingItemId, e.target.value, row.original.Id)
              }
              disabled={row.original.Status == 3 || row.original.BookingItemId == 0}
              defaultValue={row.original.Status}
              bsSize="sm"
              className=" form-control"
            >
              {obj.map((item, index) => {
                return (
                  <option key={index} value={item.Value}>
                    {item.DisplayName}
                  </option>
                )
              })}
            </Input>
          </div>
        },
      },
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "5%",
        Cell: ({ row }) => (
          <>
            {row.original.TotalProduct == 0 ? (
              <div>{row.original.BillNo}</div>
            ) : (
              <div
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleViewBookingDetails(row.original.Id)
                }}
              >
                {row.original.BillNo}
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Product Code",
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: "Product Name",
        accessor: "ProductName",
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "7%",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Rent || 0)}
            </div>
          )
        },
      },
      {
        Header: "Qty",
        accessor: "Qty",
        width: "5%",
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
          >
            {row?.original?.Remarks?.length > 30
              ? `${row?.original?.Remarks.substr(0, 30)}...`
              : row?.original?.Remarks == ""
                ? "N/A"
                : row?.original?.Remarks == null
                  ? "N/A"
                  : row?.original?.Remarks}
          </p>
        ),
      },
      {
        Header: "Return Remarks",
        accessor: "ReturnRemarks",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.ReturnRemarks == null ? '' : row.original.ReturnRemarks}`}
          >
            {row?.original?.ReturnRemarks?.length > 30
              ? `${row?.original?.ReturnRemarks.substr(0, 30)}...`
              : row?.original?.ReturnRemarks == ""
                ? "N/A"
                : row?.original?.ReturnRemarks == null
                  ? "N/A"
                  : row?.original?.ReturnRemarks}
          </p>
        ),
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    [tableData]
  )

  document.title = `Return | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody style={{ paddingTop: "10px" }}>
            <ReturnTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              dateDisble={dateDisble}
              setdateDisble={setdateDisble}
              date={date}
              setdate={setdate}
              categories={categories}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              isGroupedData={isGroupedData}
              setIsGroupedData={setIsGroupedData}
            />
          </CardBody>

          <InvoiceDetalis
            toggle={toggle}
            modal={modal}
            bookingId={bookingId}
          />

          {DeliveryModelFlag &&
            <CommonModel
              modal={DeliveryModelFlag}
              toggle={toggleDelivery}
              BookingandStatus={BookingIdandStatusId}
              flag={"delivery"}
            />
          }

        </Card>
      </div>
    </div>
  )
}
Return.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Return
