export const DeliverySlipHTML = `
        <td style="padding: 2px;  vertical-align: top; text-align: start;">
            <table style="width: 100%; border: 1px solid black; table-layout: fixed; padding: 5px;">
                <tr>
                    <td style="width: 50%;  padding-left: 3px; text-align: left;"><b>Bill#: {BILL_NO}</b></td>
                    <td style="width: 50%;  padding-right: 3px; text-align: right;"><b>Bag No. {BAG_NO}</b></td>
                </tr>
                <tr>
                    <td colspan="2" style="text-align: left;padding-left: 3px; ">{NAME}</td>
                </tr>
                <tr>
                    <td style="width: 50%; padding-left: 3px;">Delivery</td>
                    <td style="width: 50%; padding-right: 3px; text-align: right;">Return</td>
                </tr>
                <tr>
                    <td style=" padding-left: 3px;">{DELIVERY_DATE}</td>
                    <td style="text-align: right;  padding-right: 3px;">{RETURN_DATE}</td>
                </tr>
                <tr>
                    <td style="border-top-style:solid  !important; border-top-width: thin  !important;  padding-left: 3px;" colspan="2">{PRODUCT_CODE}</td>
                </tr>
                {BILL_REMARKS}
            </table>
        </td>
`;
