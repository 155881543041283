// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import toast from "react-hot-toast"
//import components
import TableList from "../../../components/Common/TableList"
import { Input } from "reactstrap"
import GetObjectsByValues from "_mock/Static_data"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import CommonModel from "../CommonModel"

function ProductView({ productViewId, modal, setModal }) {
  // useState
  const [tableData, setTableData] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [statusCancel, setStatusCancel] = useState(false)
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", bookingItemId: "", returned: false, bookingItem: false })
  const [CommonModelFlag, setCommonModelFlag] = useState(false)
  const [flag, setFlag] = useState("")
  const { getApi, putApi } = useApi()

  // useEffect product List
  useEffect(() => {
    setIsLoading(true)
    setStatusCancel(false)
    if (productViewId) {
      getApi(`Booking/booking-item-list?id=${String(productViewId)}`).then(
        ({ data, status }) => {
          if (status === 200) {
            setIsLoading(false)
            setTableData(data)
          } else {
            setIsLoading(false)
            setError(data)
          }
        }
      )
    }
  }, [statusCancel])


  const toggleCommon = () => {
    if (CommonModelFlag) {
      setStatusCancel(true)
    }
    else {
      setStatusCancel(false)
    }
    setCommonModelFlag(!CommonModelFlag)
  }

  // product status change handlerp
  const handleProductStatus = (bookingItemId, statusId, row) => {
    if (statusId == 4) {
      toggleCommon()
      setFlag("cancelItem")
      setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: productViewId, statusId: statusId, bookingItemId: bookingItemId, returned: false, bookingItem: true })
    } else if (statusId == 2 || statusId == 3) {
      toggleCommon()
      setFlag("delivery")
      setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: productViewId, statusId: statusId, bookingItemId: bookingItemId, returned: statusId == 3 ? true : false, bookingItem: true })
    }
    else {
      if ((bookingItemId, statusId)) {

        const bookingId = ""
        putApi("Booking/change-Status", {
          bookingId: bookingId == "" ? 0 : bookingId,
          bookingItemIds: bookingItemId,
          BookingStatus: parseInt(statusId),
          returnRemarks: "",
        }).then(({ data, status }) => {
          if (status === 200) {
            toast.success("Status change sucessfully", {
              style: {
                fontSize: "15px",
              },
            })
            setModal(!modal)
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
    }


  }

  // product view table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: "Name",
        accessor: "ProductName",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.NetRent || 0)}

            </div>
          )
        },
      },
      {
        Header: "Qty",
        accessor: "Qty",
        width: "4%",
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        width: "7%",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks}`}
          >
            {row.original?.Remarks?.length > 15
              ? `${row.original.Remarks.substr(0, 15)}...`
              : row.original.Remarks == ""
                ? "N/A"
                : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: "Return Remarks",
        accessor: "ReturnRemarks",
        width: "7%",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.ReturnRemarks}`}
          >
            {row.original?.ReturnRemarks?.length > 15
              ? `${row.original.ReturnRemarks.substr(0, 15)}...`
              : (row.original.ReturnRemarks == null || row.original.ReturnRemarks == '')
                ? "N/A"
                : row.original.ReturnRemarks}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Status
          </div>
        ),
        accessor: "Status",
        width: "7%",
        Cell: ({ row }) => {
          const { Status } = row.original;
          let obj;

          if (Status === 1) {
            obj = GetObjectsByValues(1, 2, 3, 4);
          } else if (Status === 2) {
            obj = GetObjectsByValues(2, 3, 4);
          } else if (Status === 3) {
            obj = GetObjectsByValues(3);
          } else {
            obj = GetObjectsByValues(4);
          }

          return <div
            className="textWrap"
            style={{
              height: "8px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Input
              type="select"
              onChange={e =>
                handleProductStatus(row.original.Id, e.target.value, row)
              }
              disabled={row.original.Status == 4}
              defaultValue={row.original.Status}
              bsSize="sm"
              className=" form-control"
            >
              {obj.map((item, index) => {
                return (
                  <option key={index} value={item.Value}>
                    {item.DisplayName}
                  </option>
                )
              })}
            </Input>
          </div>
        },
      },
    ],
    [tableData]
  )

  return (
    <div>
      <div>
        <TableList
          columns={columns}
          data={tableData}
          isGlobalFilter={true}
          isCategoryList={true}
          customPageSize={10}
          isLoading={isLoading}
          className="custom-header-css"
        />

        {CommonModelFlag &&
          <CommonModel
            modal={CommonModelFlag}
            toggle={toggleCommon}
            BookingandStatus={BookingIdandStatusId}
            setIsLoading={setIsLoading}
            flag={flag}
          />
        }

      </div>
    </div>
  )
}
ProductView.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ProductView
