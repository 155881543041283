import { routes } from "routes/path"

const breadcrumbs = {
  [routes.dashboard]: {
    title: "Dashboard",
    breadcrumbItem: "Dashboard",
  },
  [routes.userCreate]: {
    parent: "Users",
    breadcrumbItem: "Create User",
    parentLink: routes.userList,
  },
  [routes.userUpdate]: {
    parent: "Users",
    breadcrumbItem: "Update User",
    parentLink: routes.userList,
  },
  [routes.userList]: {
    title: "Users",
    breadcrumbItem: "Users",
    link: routes.userList,
  },
  [routes.inquiries]: {
    title: "Inquiries",
    breadcrumbItem: "Inquiries",
  },
  [routes.tutorial]: {
    title: "Tutorial",
    breadcrumbItem: "Tutorial",
  },
  [routes.tutorialForVendor]: {
    title: "Tutorials",
    breadcrumbItem: "Tutorials",
  },
  [routes.plan]: {
    title: "Plans",
    breadcrumbItem: "Plans",
  },
  [routes.feedback]: {
    title: "Feedback",
    breadcrumbItem: "Feedback",
  },
  [routes.finance]: {
    title: "Finance",
    breadcrumbItem: "Finance",
  },
  [routes.setting]: {
    title: "Settings",
    breadcrumbItem: "Settings",
  },
  [routes.dbBackup]: {
    title: "Db-Backup",
    breadcrumbItem: "Db-Backup",
  },
  [routes.subscription]: {
    title: "Subscription",
    breadcrumbItem: "Subscription",
  },
  [routes.categories]: {
    title: "Categories",
    breadcrumbItem: "Categories",
  },
  [routes.accessory]: {
    title: "Accessory Value",
    breadcrumbItem: "Accessory Value",
  },
  [routes.reminder]: {
    title: "Reminder",
    breadcrumbItem: "Reminder",
  },
  [routes.accounts]: {
    title: "Accounts",
    breadcrumbItem: "Accounts",
  },
  [routes.items]: {
    title: "Items",
    breadcrumbItem: "Items",
  },
  [routes.size]: {
    title: "Size",
    breadcrumbItem: "Size",
  },
  [routes.color]: {
    title: "Colors",
    breadcrumbItem: "Colors",
  },
  [routes.timeslot]: {
    title: "Time Slots",
    breadcrumbItem: "Time Slots",
  },
  [routes.createProduct]: {
    parent: "Products",
    breadcrumbItem: "Create Product",
    parentLink: routes.productList,
  },
  [routes.updateproduct]: {
    parent: "Products",
    breadcrumbItem: "Update Product",
    parentLink: routes.productList,
  },
  [routes.catalogue]: {
    title: "Products Catalogue",
    breadcrumbItem: "Products Catalogue",
  },
  [routes.productAvailable]: {
    title: "Products Available",
    breadcrumbItem: "Products Available",
  },
  [routes.groomWellProfile]: {
    title: "GroomWell Profile",
    breadcrumbItem: "GroomWell Profile",
  },
  [routes.mapCategory]: {
    title: "Map Category",
    breadcrumbItem: "Map Category",
  },
  [routes.exportProduct]: {
    title: "Export Product",
    breadcrumbItem: "Export Product",
  },
  [routes.groomWellProduct]: {
    title: "GroomWell Products",
    breadcrumbItem: "GroomWell Products",
  },
  [routes.productList]: {
    title: "Products",
    breadcrumbItem: "Products",
  },
  [routes.productHistory]: {
    title: "Product History",
    breadcrumbItem: "Product History",
  },
  [routes.createbooking]: {
    parent: "Bookings",
    breadcrumbItem: "Create Booking",
    parentLink: routes.bookingList,
  },
  [routes.updatebooking]: {
    parent: "Bookings",
    breadcrumbItem: "Update Booking",
    parentLink: routes.bookingList,
  },
  [routes.bookingList]: {
    title: "Bookings",
    breadcrumbItem: "Bookings",
  },
  [routes.cartlist]: {
    title: "Cart",
    breadcrumbItem: "Cart",
  },
  [routes.reportBooking]: {
    title: "Bookings",
    breadcrumbItem: "Bookings",
  },
  [routes.delivery]: {
    title: "Deliveries",
    breadcrumbItem: "Deliveries",
  },
  [routes.return]: {
    title: "Returns",
    breadcrumbItem: "Returns",
  },
  [routes.security]: {
    title: "Security",
    breadcrumbItem: "Security",
  },
  [routes.accessoryReport]: {
    title: "Accessories Report",
    breadcrumbItem: "Accessories Report",
  },
  [routes.pendingBills]: {
    title: "Pending Bills",
    breadcrumbItem: "Pending Bills",
  },
  [routes.salesman]: {
    title: "Salesman",
    breadcrumbItem: "Salesman",
  },
  [routes.BookedOrderReport]: {
    title: "Booked Orders",
    breadcrumbItem: "Booked Orders",
  },
  [routes.available]: {
    title: "Available",
    breadcrumbItem: "Available",
  },
  [routes.profile]: {
    title: "User Profile",
    breadcrumbItem: "User Profile",
  },
  // [routes.HoldBillList]: {
  //   title: "Hold bills",
  //   breadcrumbItem: "Hold bills",
  // },
  [routes.stockRegister]: {
    title: "Stock Register",
    breadcrumbItem: "Stock Register",
  },
  [routes.income]: {
    title: "Income",
    breadcrumbItem: "Income",
  },
  [routes.expenses]: {
    title: "Expenses",
    breadcrumbItem: "Expenses",
  },
  [routes.journalVouchers]: {
    title: "Journal Vouchers",
    breadcrumbItem: "Journal Vouchers",
  },
  [routes.incomeExpense]: {
    title: "Incomes & Expenses",
    breadcrumbItem: "Incomes & Expenses",
  },
  [routes.accountLedger]: {
    title: "Account Ledger",
    breadcrumbItem: "Account Ledger",
  },
  [routes.decrypt]: {
    title: "Decrypt",
    breadcrumbItem: "Decrypt",
  },
  [routes.createSale]: {
    parent: "Sales",
    breadcrumbItem: "Create Sale",
    parentLink: routes.salesList,
  },
  [routes.updateSale]: {
    parent: "Sales",
    breadcrumbItem: "Update Sale",
    parentLink: routes.salesList,
  },
  [routes.salesList]: {
    title: "Sales",
    breadcrumbItem: "Sales",
  },
  [routes.purchaseList]: {
    title: "Purchase",
    breadcrumbItem: "Purchase",
  },
  [routes.createPurchase]: {
    parent: "Purchase",
    breadcrumbItem: "Create purchase",
    parentLink: routes.purchaseList,
  },
  [routes.UpdatePurchase]: {
    parent: "Purchase",
    breadcrumbItem: "Update Purchase",
    parentLink: routes.purchaseList,
  },
  [routes.releseNote]: {
    title: "Relese Note",
    breadcrumbItem: "What's New",
  },
  [routes.CreditNote]: {
    title: "Credit Notes",
    breadcrumbItem: "Credit Notes",
  },
  [routes.CreateCreditNote]: {
    breadcrumbItem: "Create Credit Notes",
    parent: "Credit Notes",
    parentLink: routes.CreditNote,
  },
  [routes.salesGSTReport]: {
    breadcrumbItem: "Sales",
    parent: "Sales",
  },
  [routes.washing]: {
    title: "Washing",
    breadcrumbItem: "Washing",
    parent: "Washing",
  },
  [routes.customers]: {
    title: "Customers",
    breadcrumbItem: "Customers",
    parent: "Customers",
  },
  [routes.systemLog]: {
    title: "System Logs",
    breadcrumbItem: "System Logs",
    parent: "System Logs",
  },
}

export default breadcrumbs
